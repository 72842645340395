import * as React from 'react'
import { useState } from 'react'
import { useRedirect, useNotify, Notification } from 'react-admin'
import {
    Icon,
    Container,
    CssBaseline,
    TextField,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import api from '../Services/Api'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    iconRoot: {
        textAlign: 'center',
        width: 300,
        height: 150,
    },
    listItem: {
        color: 'red',
    },
}))

const ResetPassword = (params) => {
    const token = params.match.params.token
    const classes = useStyles()
    const [newPassword, setNewPassword] = useState('')
    const [newPassword2, setNewPassword2] = useState('')

    const notify = useNotify()
    const redirect = useRedirect()

    const submit = async (e) => {
        e.preventDefault()
        if (newPassword && newPassword === newPassword2) {
            const strongPassword = new RegExp(
                '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
            )
            const isStrongPassword = strongPassword.test(newPassword)

            if (!isStrongPassword)
                return notify(
                    'A senha não atende os requisitos mínimos de segurança.'
                )

            let result = null
            try {
                result = await api.post(
                    '/admin/resetPassword',
                    { newPassword },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                if (result.status === 200 && !result.data.error) {
                    notify('Senha alterada com sucesso!')
                    redirect('/login')
                } else {
                    notify(result.data.error)
                }
            } catch (error) {
                notify('Erro ao alterar senha, tente novamente mais tarde.')
            }
        } else {
            notify('As senhas não conferem.')
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Icon classes={{ root: classes.iconRoot }}>
                    <img src="./melhorseguro.png" alt="logo" />
                </Icon>
                <form className={classes.form} onSubmit={submit} noValidate>
                    <Typography>A nova senha deve conter no mínimo:</Typography>
                    <List dense>
                        <ListItem>
                            <ListItemText
                                className={classes.listItem}
                                primary="1 letra maiúscula"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                className={classes.listItem}
                                primary="1 letra minúscula"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                className={classes.listItem}
                                primary="1 número"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                className={classes.listItem}
                                primary="8 caracteres"
                            />
                        </ListItem>
                    </List>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="newPassword"
                        label="Nova senha"
                        name="newPassword"
                        type="password"
                        autoComplete="newPassword"
                        autoFocus
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="newPassword2"
                        label="Repetir nova senha"
                        name="newPassword2"
                        type="password"
                        autoComplete="newPassword2"
                        value={newPassword2}
                        onChange={(e) => setNewPassword2(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Enviar
                    </Button>
                </form>
            </div>
            <Notification />
        </Container>
    )
}

export default ResetPassword
