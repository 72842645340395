import jsonServerProvider from 'ra-data-json-server'
import { fetchUtils } from 'react-admin'
import { API_URL } from './consts';

let providerAddress = API_URL;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    const { token } = JSON.parse(localStorage.getItem('auth'))
    options.headers.set('Authorization', `Bearer ${token}`)
    return fetchUtils.fetchJson(url, options)
}

const dataProvider = jsonServerProvider(providerAddress, httpClient)

const myDataProvider = {
    ...dataProvider,
    update: (resource, params) => {
        if (!params.data.image) {
            return dataProvider.update(resource, params)
        }

        let pictures = [params.data.image]

        //se for multiplas imagens
        if (Array.isArray(pictures[0]))
            pictures = pictures[0]

        const newPictures = pictures.filter(
            p => p.rawFile instanceof File
        )
        const formerPictures = pictures.filter(
            p => !(p.rawFile instanceof File)
        )

        return Promise.all(newPictures.map(convertFileToBase64))
            .then(base64Pictures =>
                base64Pictures.map(picture64 => ({
                    src: picture64.src,
                    title: picture64.title,
                }))
            )
            .then(transformedNewPictures =>
                dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        pictures: [
                            ...transformedNewPictures,
                            ...formerPictures,
                        ],
                    },
                })
            )
    },
    create: (resource, params) => {
        if (!params.data.image) {
            return dataProvider.create(resource, params)
        }

        const pictures = [params.data.image]
        const newPictures = pictures.filter(
            p => p.rawFile instanceof File
        )
        const formerPictures = pictures.filter(
            p => !(p.rawFile instanceof File)
        )

        return Promise.all(newPictures.map(convertFileToBase64))
            .then(base64Pictures =>
                base64Pictures.map(picture64 => ({
                    src: picture64.src,
                    title: picture64.title,
                }))
            )
            .then(transformedNewPictures =>
                dataProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        pictures: [
                            ...transformedNewPictures,
                            ...formerPictures,
                        ],
                    },
                })
            )
    },
}

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve({ src: reader.result, title: file.title })
        reader.onerror = reject

        reader.readAsDataURL(file.rawFile)
    })

export default myDataProvider
