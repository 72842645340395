import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    RichTextField,
    useShowController,
    TextInput,
    NumberInput,
    ShowButton,
    TopToolbar,
    Filter,
    useCheckAuth,
    useNotify,
    useRefresh,
    DatagridBody,
    downloadCSV,
    SelectInput,
    DateInput,
    Edit,
    SimpleForm,
    UrlField,
    FunctionField,
    ChipField,
    Chip,
    Stack,
    ArrayField,
    SingleFieldList,
    ReferenceField,
    ReferenceArrayField,
    StringToLabelObject,
    ReferenceManyField,
    DeleteButton,
    useRecordContext,
    WithListContext
} from 'react-admin'
import {
    Button,
    Grid,
    TextField,
    Box,
    Paper,
    Tabs,
    Tab,
    Typography,
    AppBar,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip,
    TableCell,
    TableRow,
    FormControl,InputLabel,Select,MenuItem
} from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
// import BackButton from '../../../components/BackButton'
import api from '../../../Services/Api'
// import { formatDate, formatDateTime, formatXmlDate } from '../../../functions'
// import jsonExport from 'jsonexport/dist'
// import formatter from '../../../formatter'
import searchIcon from '@material-ui/icons/Search'
import removedIcon from '@material-ui/icons/Delete'
export const SearchIcon = searchIcon;
export const RemovedIcon = removedIcon;

export const SearchFilter = (props) => {
    
    const [insurerOptions, setInsurerOptions] = React.useState([])
    const [destinationOptions, setDestinationOptions] = React.useState([])

    useEffect(() => {
        ;(async () => {
            try {
                const result = await api.get(
                    `admin/destinations?_order=ASC&_sort=name`
                )
                setDestinationOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar coberturas', error)
            }
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            try {
                const result = await api.get(`admin/insurers?_order=ASC&_sort=name`)
                let insurers = [...[{ id: 0, name: "Melhor Seguro" }], ...result?.data];
                setInsurerOptions(insurers)
            } catch (error) {
                console.log('Erro ao consultar insurers', error)
            }
        })()
    }, [])

    return (
    <Filter {...props}>
        <NumberInput label="Idade" source="age" alwaysOn />        
        <DateInput label="Data Ínício" source="startDate" style={{ width: 180 }} alwaysOn/>
        <DateInput label="Data Fim" source="endDate" style={{ width: 180 }} alwaysOn/>        
        <SelectInput label="Destino" source="destinationId" choices={destinationOptions} alwaysOn/>        
        <SelectInput label="Seguradora" source="insurerId" choices={insurerOptions} alwaysOn/>        
    </Filter>
    )
}

export const SearchList = (props) => {
    // const classes = useStyles();
    // const [showMore, setShowMore] = useState(false);

    const MyDatagridRow = ({ record, resource, id, children, basePath }) => (
        <TableRow key={id}>
            {React.Children.map(children, (field) => {
                let style = { padding: '10px' }
                if (
                    field.props.source === 'checkIn' ||
                    field.props.source === 'checkOut' ||
                    field.props.source === 'id'
                )
                    style.width = '75px'

                if (field.props.source === 'total') {
                    style.textAlign = 'left'
                    style.width = '100px'
                    style.minWidth = '100px'
                }

                if (field.props.source === 'roomsCount') {
                    style.width = '50px'
                    style.textAlign = 'center'
                }

                if (field.props.source === 'reservationId')
                    style.width = '150px'

                if (field.props.source === 'statusText') style.width = '200px'

                return (
                    <TableCell
                        key={`${id}-${field.props.source}`}
                        style={style}
                    >
                        {React.cloneElement(field, {
                            record,
                            basePath,
                            resource,
                        })}
                    </TableCell>
                )
            })}
        </TableRow>
    )

    const MyDatagridBody = (props) => (
        <DatagridBody {...props} row={<MyDatagridRow />} />
    )

    const MyDatagrid = (props) => (
        <Datagrid {...props} body={<MyDatagridBody />} />
    )

    return (
        <List
            {...props}
            title="Buscador de Seguros"
            exporter={false}
            bulkActionButtons={false}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<SearchFilter />}
            filterDefaultValues={{
                age: 18,
                insurerId: 0,
                destinationId: 2,
                startDate: new Date().setDate(new Date().getDate() + 15),
                endDate: new Date().setDate(new Date().getDate() + 20)  
            }}
            perPage={50}
        >
            <MyDatagrid>
                <TextFieldRa source="id" label="Product Id" sortable={false} />
                <TextFieldRa source="description" label="description" sortable={false} />
                <TextFieldRa source="insurer" label="Seguradora" sortable={false} />
                <TextFieldRa source="productCode" label="productCode" sortable={false} />
                <TextFieldRa source="fareCode" label="fareCode" sortable={false} />                
                {/* <TextFieldRa source="DMH" label="DMH" sortable={false} /> */}
                <TextFieldRa source="fare.costTotal" label="costTotal" sortable={false} />
                {/* <ShowButton label="" /> */}
            </MyDatagrid>
        </List>
    )
}