import React from 'react'
import { useEffect } from 'react'
import api from '../../../Services/Api'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    BooleanField,
    EditButton,
    TextInput,
    BooleanInput,
    SimpleForm,
    Edit,
    DeleteButton,
    required,
    Create,
    Filter,
    SearchInput,
    SelectInput
} from 'react-admin'
import Icon from '@material-ui/icons/BeachAccess'

export const CoveragesIcon = Icon

const ProductFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        {/* <BooleanInput
            label="Exibir na pesquisa?"
            source="showInResults"
            alwaysOn
        />
        <BooleanInput
            label="Exibir como Higlight?"
            source="showInHighlights"
            alwaysOn
        /> */}
        <SelectInput
            label="Exibir na pesquisa?"
            source="showInResults"
            choices={[
                { id: "", name: 'Todos' },
                { id: true, name: 'Sim' },
                { id: false, name: 'Não' },
            ]}
            alwaysOn
        />
        <SelectInput
            label="Exibir como Higlight?"
            source="showInHighlights"
            choices={[
                { id: "", name: 'Todos' },
                { id: true, name: 'Sim' },
                { id: false, name: 'Não' },
            ]}
            alwaysOn
        />
    </Filter>
)

export const CoveragesList = ({ permissions, ...props }) => {
    return (
        <List
            {...props}
            exporter={false}
            title="Produtos"
            bulkActionButtons={false}
            sort={{ field: 'order', order: 'ASC' }}
            filters={<ProductFilter />}
            perPage={25}
        >
            <Datagrid>
                <TextFieldRa source="id" label="ID" />
                <TextFieldRa source="name" label="Nome" />
                <TextFieldRa source="CoverageGroup.name" label="Grupo" />
                <BooleanField source="showInResults" label="Exibir na pesquisa?" />
                <BooleanField source="isDMH" label="DMH?" />
                <TextFieldRa source="order" label="Ordenação Geral" />
                <BooleanField source="showInHighlights" label="Exibir como highlight?" />
                <TextFieldRa source="orderHighlight" label="Ordenação Highight" />
                <EditButton label="" />
                <DeleteButton label="" mutationMode={'pessimistic'} />
            </Datagrid>
        </List>
    )
}

export const CoveragesEdit = (props) => {
    const [coverageGroupsOptions, setCoverageGroupsOptions] = React.useState([])

    useEffect(() => {
        ; (async () => {
            try {
                const result = await api.get(`admin/coverageGroups`)
                setCoverageGroupsOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar coverageGroups', error)
            }
        })()
    }, [])

    return (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <TextInput
                    label="Nome"
                    source="name"
                    validate={[required()]}
                    style={{ width: "100%" }}
                />
                <p><strong>Dados para Cobertura Completa:</strong></p>
                <TextInput
                    label="Descrição completa"
                    source="description"
                    multiline
                    style={{ width: "100%" }}
                />
                <SelectInput
                    label="Grupo"
                    source="groupId"
                    choices={coverageGroupsOptions}
                    resettable
                    style={{ width: "100%" }}
                />
                <TextInput
                    label="Ordenação na cobertura completa"
                    source="order"
                    style={{ width: "100%" }}
                />
                <BooleanInput
                    source="showInResults"
                    label="Exibir na pesquisa?"
                    style={{ width: "100%" }}
                />
                <BooleanInput
                    source="isDMH"
                    label="DMH?"
                    style={{ width: "100%" }}
                />
                <p><strong>Dados para os Highlights:</strong></p>
                <BooleanInput
                    source="showInHighlights"
                    label="Exibir como Highlight?"
                    style={{ width: "100%" }}
                />
                <TextInput
                    label="Descrição resumida highlight"
                    source="descriptionHighlight"
                    multiline
                    style={{ width: "100%" }}
                />
                <TextInput
                    label="Ordenação nos Highlights"
                    source="orderHighlight"
                    style={{ width: "100%" }}
                />
                <TextInput
                    label="Ícone"
                    source="icon"
                    multiline
                    style={{ width: "100%" }}
                />
            </SimpleForm>
        </Edit>
    )
}

export const CoveragesCreate = (props) => {
    const [coverageGroupsOptions, setCoverageGroupsOptions] = React.useState([])

    useEffect(() => {
        ; (async () => {
            try {
                const result = await api.get(`admin/coverageGroups`)
                setCoverageGroupsOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar coverageGroups', error)
            }
        })()
    }, [])

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput
                    label="Nome"
                    source="name"
                    validate={[required()]}
                    style={{ width: "100%" }}
                />
                <p><strong>Dados para Cobertura Completa:</strong></p>
                <TextInput
                    label="Descrição completa"
                    source="description"
                    multiline
                    style={{ width: "100%" }}
                />
                <SelectInput
                    label="Grupo"
                    source="groupId"
                    choices={coverageGroupsOptions}
                    resettable
                    style={{ width: "100%" }}
                />
                <TextInput
                    label="Ordenação na cobertura completa"
                    source="order"
                    style={{ width: "100%" }}
                />
                <BooleanInput
                    source="showInResults"
                    label="Exibir na pesquisa?"
                    style={{ width: "100%" }}
                />
                <BooleanInput
                    source="isDMH"
                    label="DMH?"
                    style={{ width: "100%" }}
                />
                <p><strong>Dados para os Highlights:</strong></p>
                <BooleanInput
                    source="showInHighlights"
                    label="Exibir como Highlight?"
                    style={{ width: "100%" }}
                />
                <TextInput
                    label="Descrição resumida highlight"
                    source="descriptionHighlight"
                    multiline
                    style={{ width: "100%" }}
                />
                <TextInput
                    label="Ordenação nos Highlights"
                    source="orderHighlight"
                    style={{ width: "100%" }}
                />
                <TextInput
                    label="Ícone"
                    source="icon"
                    multiline
                    style={{ width: "100%" }}
                />
            </SimpleForm>
        </Create>
    )
}
