import * as React from 'react'
import { Admin, Resource } from 'react-admin'
import customRoutes from './customRoutes'
import authProvider from './authProvider'
import { OrderEdit, OrderList, OrderShow } from './resources/orders/orders'
import {
    UserList,
    UserShow,
    UserCreate,
    UserEdit,
    UserIcon,
} from './resources/users/users'
import { TagList, TagEdit, TagCreate, TagIcon } from './resources/tags/tags'
import {
    CouponList,
    CouponEdit,
    CouponCreate,
    CouponIcon,
} from './resources/coupons/coupons'
import {
    destinyHighlightsList,
    DestinyHighlightsCreate,
    destinyHighlightsIcon,
} from './resources/destinyHighlights/destinyHighlights'
import closeSidebarSaga from './closeSidebarSaga'
import ptBrMessages from 'ra-language-pt-br'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import myDataProvider from './dataProvider'
import MyLoginPage from './pages/myLoginPage'

//assistance
import {
    AssistanceOrderEdit,
    AssistanceOrderList,
    AssistanceOrderListQueue,
    AssistanceOrderListRemoved,
    AssistanceOrderShow,
    QueueIcon,
    RemovedIcon
} from './resources/assistance/assistanceOrders/assistanceOrders'
import {
    InsurersList,
    InsurersEdit,
    InsurersCreate,
    InsurersIcon,
} from './resources/assistance/insurers/insurers'
import {
    LabelsList,
    LabelsEdit,
    LabelsCreate,
    LabelsIcon,
} from './resources/assistance/labels/labels'
import {
    DestinationsList,
    DestinationsEdit,
    DestinationsCreate,
    DestinationsIcon,
} from './resources/assistance/destinations/destinations'
import {
    ProductDestinationsList,
    ProductDestinationsCreate,
} from './resources/assistance/productDestinations/productDestinations'
import {
    SearchList,
    SearchIcon
} from './resources/assistance/search/search'
import {
    BenchmarkList,
    // SearchIcon
} from './resources/assistance/benchmark/benchmark'
import {
    PaymentTypesList,
    PaymentTypesEdit,
    PaymentTypesCreate,
    PaymentTypesIcon,
} from './resources/assistance/paymentTypes/paymentTypes'
import {
    AssistanceCouponList,
    AssistanceCouponEdit,
    AssistanceCouponCreate,
    AssistanceCouponIcon,
} from './resources/assistance/assistanceCoupons/assistanceCoupons'
import {
    ProductsList,
    ProductsShow,
    ProductsEdit,
    ProductsCreate,
    ProductsIcon
} from './resources/assistance/products/products'
import {
    CoveragesList,
    CoveragesEdit,
    CoveragesCreate,
    CoveragesIcon,
} from './resources/assistance/coverages/coverages'
import {
    ExchangeRatesList,
    ExchangeRatesEdit,
    ExchangeRatesCreate,
    ExchangeRatesIcon,
} from './resources/assistance/exchangeRates/exchangeRates'
import {
    ProductCoveragesEdit,
    ProductCoveragesCreate,
} from './resources/assistance/productCoverages/productCoverages'
import {
    AssistanceTagList,
    AssistanceTagEdit,
    AssistanceTagCreate,
    AssistanceTagIcon,
} from './resources/assistance/assistanceTags/assistanceTags'
import {
    ProductTagEdit,
    ProductTagCreate,
    ProductTagIcon,
} from './resources/assistance/productTags/productTags'
import {
    ProductFareAgeEdit,
    ProductFareAgeCreate,
    ProductFareAgeIcon,
} from './resources/assistance/productFareAges/productFareAges'
import {
    CoverageGroupList,
    CoverageGroupEdit,
    CoverageGroupCreate,
    CoverageGroupIcon,
} from './resources/assistance/coverageGroups/coverageGroups'
import {
    SettingsList,
    SettingsEdit,
    SettingsIcon,
} from './resources/assistance/settings/settings'

const messages = {
    'pt-br': ptBrMessages,
}
const i18nProvider = polyglotI18nProvider(
    (locale) => messages[locale],
    'pt-br',
    { allowMissing: true, onMissingKey: (key, _, __) => key }
)

//const i18nProvider = polyglotI18nProvider(() => ENi18n, "en", { allowMissing: true, onMissingKey: (key, _, __) => key });

const App = () => {
    return (
        <Admin
            customSagas={[closeSidebarSaga]}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            loginPage={MyLoginPage}
            customRoutes={customRoutes}
            dataProvider={myDataProvider}
        >
            {(permissions) => {
                return [
                    permissions.includes('assistanceadmin') || permissions.includes('assistanceuser') ?
                        [
                              <Resource
                                  name="admin/assistanceOrders"
                                  options={{ label: 'Pedidos (Seguros)' }}
                                  list={AssistanceOrderList}
                                  show={AssistanceOrderShow}
                                  edit={AssistanceOrderEdit}
                              />,
                              <Resource
                                  name="admin/assistanceOrdersQueue"
                                  options={{ label: 'Pedidos (Fila)' }}
                                  list={AssistanceOrderListQueue}
                                  show={AssistanceOrderShow}
                                  edit={AssistanceOrderEdit}
                                  icon={QueueIcon}
                              />,
                              <Resource
                                  name="admin/assistanceOrdersRemoved"
                                  options={{ label: 'Pedidos (Removidos)' }}
                                  list={AssistanceOrderListRemoved}
                                  show={AssistanceOrderShow}
                                  edit={AssistanceOrderEdit}
                                  icon={RemovedIcon}
                              />
                        ] : null,
                    permissions.includes('sysadmin') ? [
                              <Resource
                                  name="admin/labels"
                                  options={{ label: 'Labels' }}
                                  list={LabelsList}
                                  create={LabelsCreate}
                                  edit={LabelsEdit}
                                  icon={LabelsIcon}
                              />,
                              <Resource
                                  name="admin/insurers"
                                  options={{ label: 'Seguradoras' }}
                                  list={InsurersList}
                                  create={InsurersCreate}
                                  edit={InsurersEdit}
                                  icon={InsurersIcon}
                              />,
                              <Resource
                                  name="admin/products"
                                  options={{ label: 'Produtos' }}
                                  list={ProductsList}
                                  create={ProductsCreate}
                                  edit={ProductsEdit}
                                  show={ProductsShow}
                                  icon={ProductsIcon}
                              />,
                              <Resource
                                  name="admin/coverages"
                                  options={{ label: 'Coberturas' }}
                                  list={CoveragesList}
                                  create={CoveragesCreate}
                                  edit={CoveragesEdit}
                                  icon={CoveragesIcon}
                              />,
                              <Resource
                                  name="admin/productCoverages"
                                  options={{ label: 'Cobertura' }}
                                  create={ProductCoveragesCreate}
                                  edit={ProductCoveragesEdit}
                              />,
                              <Resource
                                  name="admin/coverageGroups"
                                  options={{ label: 'Grupo de Coberturas' }}
                                  list={CoverageGroupList}
                                  create={CoverageGroupCreate}
                                  edit={CoverageGroupEdit}
                                  icon={CoverageGroupIcon}
                              />,
                              <Resource
                                  name="admin/assistanceTags"
                                  options={{ label: 'Tags' }}
                                  list={AssistanceTagList}
                                  create={AssistanceTagCreate}
                                  edit={AssistanceTagEdit}
                                  icon={AssistanceTagIcon}
                              />,
                              <Resource
                                  name="admin/productTags"
                                  options={{ label: 'ProductTags' }}
                                  create={ProductTagCreate}
                                  edit={ProductTagEdit}
                                  icon={ProductTagIcon}
                              />,
                              <Resource
                                  name="admin/productFareAges"
                                  options={{ label: 'ProductFareAges' }}
                                  create={ProductFareAgeCreate}
                                  edit={ProductFareAgeEdit}
                                  icon={ProductFareAgeIcon}
                              />,
                              <Resource
                                  name="admin/destinations"
                                  options={{ label: 'Destinos' }}
                                  list={DestinationsList}
                                  create={DestinationsCreate}
                                  edit={DestinationsEdit}
                                  icon={DestinationsIcon}
                              />,
                              <Resource
                                name="admin/productDestinations"
                                options={{ label: 'Destinos Cadastro' }}
                                list={ProductDestinationsList}
                                create={ProductDestinationsCreate}
                                //edit={ProductDestinationsEdit}
                                icon={DestinationsIcon}
                             />,
                             <Resource
                                name="admin/search"
                                options={{ label: 'Buscador' }}
                                list={SearchList}
                                icon={SearchIcon}
                             />,
                             <Resource
                                name="admin/benchmark"
                                options={{ label: 'Bechmark' }}
                                list={BenchmarkList}
                                icon={SearchIcon}
                             />,
                              <Resource
                                  name="admin/paymentTypes"
                                  options={{ label: 'Formas de pagamento' }}
                                  list={PaymentTypesList}
                                  create={PaymentTypesCreate}
                                  edit={PaymentTypesEdit}
                                  icon={PaymentTypesIcon}
                              />,
                              <Resource
                                  name="admin/assistanceCoupons"
                                  options={{ label: 'Cupons' }}
                                  list={AssistanceCouponList}
                                  create={AssistanceCouponCreate}
                                  edit={AssistanceCouponEdit}
                                  icon={AssistanceCouponIcon}
                              />,                                                            
                              <Resource
                                  name="admin/exchangeRates"
                                  options={{
                                      label: 'Câmbio',
                                  }}
                                  list={ExchangeRatesList}
                                  create={ExchangeRatesCreate}
                                  edit={ExchangeRatesEdit}
                                  icon={ExchangeRatesIcon}
                              />,                              
                              <Resource
                                  name="admin/settings"
                                  options={{ label: 'Configurações' }}
                                  list={SettingsList}
                                  edit={SettingsEdit}
                                  icon={SettingsIcon}
                              />,
                              <Resource
                                  name="admin/users"
                                  options={{ label: 'Usuários' }}
                                  list={UserList}
                                  show={UserShow}
                                  create={UserCreate}
                                  edit={UserEdit}
                                  icon={UserIcon}
                              />,
                        ]
                        : null,
                ]
            }}
        </Admin>
    )
}

export default App