import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    RichTextField,
    useShowController,
    TextInput,
    NumberInput,
    ShowButton,
    TopToolbar,
    Filter,
    useCheckAuth,
    useNotify,
    useRefresh,
    DatagridBody,
    downloadCSV,
    SelectInput,
    DateInput,
    Edit,
    SimpleForm,
    UrlField,
    FunctionField,
    ChipField,
    Chip,
    Stack,
    ArrayField,
    SingleFieldList,
    ReferenceField,
    ReferenceArrayField,
    StringToLabelObject,
    ReferenceManyField,
    DeleteButton,
    useRecordContext,
    WithListContext,
    useListContext
} from 'react-admin'
import {
    Button,
    Grid,
    TextField,
    Box,
    Paper,
    Tabs,
    Tab,
    Typography,
    AppBar,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip,
    TableCell,
    TableRow,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
// import BackButton from '../../../components/BackButton'
import api from '../../../Services/Api'
// import { formatDate, formatDateTime, formatXmlDate } from '../../../functions'
// import jsonExport from 'jsonexport/dist'
// import formatter from '../../../formatter'
import searchIcon from '@material-ui/icons/Search'
import removedIcon from '@material-ui/icons/Delete'
export const SearchIcon = searchIcon;
export const RemovedIcon = removedIcon;

export const SearchFilter = (props) => {
    
    // const { data, isLoading } = useListContext();
    // console.log(data);

    const [insurerOptions, setInsurerOptions] = React.useState([])
    const [destinationOptions, setDestinationOptions] = React.useState([])

    useEffect(() => {
        ;(async () => {
            try {
                const result = await api.get(
                    `admin/destinations?_order=ASC&_sort=name`
                )
                setDestinationOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar coberturas', error)
            }
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            try {
                const result = await api.get(`admin/insurers?_order=ASC&_sort=name`)
                let insurers = [...[{ id: 0, name: "Melhor Seguro" }], ...result?.data];
                setInsurerOptions(insurers)
            } catch (error) {
                console.log('Erro ao consultar insurers', error)
            }
        })()
    }, [])

    return (
        <>
            <Filter {...props}>
                <NumberInput label="Idade" source="age" alwaysOn />
                <SelectInput label="Destino" source="destinationId" choices={destinationOptions} alwaysOn/>
                <DateInput label="Data Ínício" source="startDate" style={{ width: 180 }} alwaysOn/>
                <DateInput label="Data Fim" source="endDate" style={{ width: 180 }} alwaysOn/>
                {/* <SelectInput label="Seguradora" source="insurerId" choices={insurerOptions} alwaysOn/> */}
            </Filter>
    </>
    )
}

export const BenchmarkList = (props) => {
    // const classes = useStyles();
    // const [showMore, setShowMore] = useState(false);

    // const { data, ids, basePath, resource } = props;
    // console.log({ data, ids, basePath, resource });

    // const { data } = useListContext();
    // console.log(data);


    const MyDatagridRow = ({ record, resource, id, children, basePath }) => (
        <TableRow key={id}>
            {React.Children.map(children, (field) => {
                let style = { padding: '10px' }
                if (
                    field.props.source === 'checkIn' ||
                    field.props.source === 'checkOut' ||
                    field.props.source === 'id'
                )
                    style.width = '75px'

                if (field.props.source === 'total') {
                    style.textAlign = 'left'
                    style.width = '100px'
                    style.minWidth = '100px'
                }

                if (field.props.source === 'roomsCount') {
                    style.width = '50px'
                    style.textAlign = 'center'
                }

                if (field.props.source === 'reservationId')
                    style.width = '150px'

                if (field.props.source === 'statusText') style.width = '200px'

                return (
                    <TableCell
                        key={`${id}-${field.props.source}`}
                        style={style}
                    >
                        {React.cloneElement(field, {
                            record,
                            basePath,
                            resource,
                        })}
                    </TableCell>
                )
            })}
        </TableRow>
    )

    const MyDatagridBody = (props) => (
        <DatagridBody {...props} row={<MyDatagridRow />} />
    )

    const MyDatagrid = (props) => (            
        <Datagrid {...props} body={<MyDatagridBody />} />
    )

    const Title = () => {
        let { data, isLoading } = useListContext();
        console.log(data);

        let array = [];
        if(data){
            Object.keys(data).map( key => {
                array.push({
                    id: key,
                    ...data[key]
                })
            });
        }
        console.log(array);

        return (
            <Grid style={{ marginBottom: 15, backgroundColor: "lightblue" }}>
                <Grid item sm={12} md={12}>
                    {/* <h4>Estatísticas</h4> */}
                    Produtos: MS {array?.filter(item => item?.MS?.id)?.length} / SP {array?.filter(item => item?.SP?.id)?.length} <br />
                    Resultados c/ match: {array?.filter(item => item?.comparative?.diffPerc)?.length} ({Math.round(array?.filter(item => item?.comparative?.diffPerc)?.length/array.length * 100)}%)<br />
                    Diff Perc Media: {Math.round(array?.filter(item => item?.comparative?.diffPerc)?.reduce((prev,curr) => prev+=curr.comparative?.diffPerc,0)/array?.filter(item => item?.comparative?.diffPerc).length)}%
                </Grid>
            </Grid>
        );
    }

    return (
        <List
            {...props}
            title="Benchmark"
            exporter={false}
            bulkActionButtons={false}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<SearchFilter />}
            filterDefaultValues={{
                age: 18,
                insurerId: 0,
                destinationId: 2,
                startDate: new Date().setDate(new Date().getDate() + 15),
                endDate: new Date().setDate(new Date().getDate() + 20)  
            }}
            actions={<Title />}
            perPage={100}
        >            
            <MyDatagrid>
                <TextFieldRa source="id" label="Product Id" sortable={false} />
                <TextFieldRa source="MS.insurer" label="Seguradora (MS)" sortable={false}  style={{ color: "purple" }}/>
                {/* <TextFieldRa source="MS.description" label="description" sortable={false}  style={{ color: "orange" }}/> */}
                <FunctionField source="MS.description" label="description (MS)" render={record => <span style={{ color: "purple" }}>{record.MS.description}<br></br>{record.MS.coverageValues?.[0]?.value}</span>} />
                <TextFieldRa source="MS.fare.fareTotal" label="fareTotal (MS)" sortable={false}  style={{ color: "purple" }}/>

                <TextFieldRa source="SP.insurer" label="Seguradora (SP)" sortable={false}  style={{ color: "orange" }}/>
                {/* <TextFieldRa source="SP.description" label="description" sortable={false}  style={{ color: "orange" }}/> */}
                <FunctionField source="SP.description" label="description (SP)" render={record => <span style={{ color: "orange" }}>{record.SP.description}<br></br>{record.SP.coverageValues?.[0]?.value}</span>} />
                <TextFieldRa source="SP.fare.fareTotalCartao" label="fareTotal (SP)" sortable={false}  style={{ color: "orange" }}/>

                {/* <TextFieldRa source="comparative.diffValue" label="diffValue" sortable={false}  style={{ color: "black" }}/> */}
                {/* <TextFieldRa source="comparative.diffPerc" label="diffPerc" sortable={false}  style={{ color: "black" }}/> */}
                
                <FunctionField label="diffPerc" render={record => <span style={{color: record.comparative.diffPerc<0 ? "green" : "red" }}>{record.comparative.diffPerc}</span>} />

                {/* <ShowButton label="" /> */}
            </MyDatagrid>
        </List>
    )
}