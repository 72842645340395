import React from 'react'
import { useEffect } from 'react'
import {
    List, Datagrid, TextField as TextFieldRa, EditButton, TextInput, SimpleForm, Edit, Filter, SearchInput, NumberInput, RadioButtonGroupInput,
    DeleteButton, required, Create, DateInput, SelectInput, downloadCSV, useEditController, FormDataConsumer, DateTimeInput, CheckboxGroupInput, FunctionField
} from 'react-admin'
import Icon from '@material-ui/icons/LocalOffer'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import jsonExport from 'jsonexport/dist'

import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import api from "../../../Services/Api"
import { formatXmlDate } from '../../../functions'
export const AssistanceCouponIcon = Icon

const CouponFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SearchInput source="partner" placeholder='Parceiro' alwaysOn />
        <SearchInput source="status" placeholder='Status (true/false)' alwaysOn />
        <DateInput source="startDate" placeholder='Início' alwaysOn />
        <DateInput source="endDate" placeholder='Término' alwaysOn />
    </Filter>
)



export const AssistanceCouponList = ({ permissions, ...props }) => {
    const validateCupomIsValid = ({ startDate, endDate, limit, uses }) => {
        const startDateFomatted = startDate?.split('/')?.reverse()?.join('-') + ' 00:00:00';
        const endDateFomatted = endDate?.split('/')?.reverse()?.join('-') + ' 00:00:00';

        const shouldBeNotValidateDates = startDateFomatted === '1970-01-01 00:00:00' || endDateFomatted === '1970-01-01 00:00:00';
        const shouldCupomBeDateValid = new Date(startDateFomatted) <= new Date() && new Date(endDateFomatted) >= new Date();
        const shouldBeValidateLimit = limit > 0;

        if (!shouldBeNotValidateDates && shouldBeValidateLimit) {
            return shouldCupomBeDateValid && !(uses > limit);
        }

        if (shouldBeNotValidateDates && shouldBeValidateLimit) {
            return !(uses > limit);
        }

        if (shouldBeNotValidateDates && !shouldBeValidateLimit) {
            return true;
        }

        if (!shouldBeNotValidateDates && !shouldBeValidateLimit) {
            return shouldCupomBeDateValid;
        }
    }

    const exporter = (orders) => {
        const ordersForExport = orders.map((order) => {
            return {
                'Cupom': order.name,
                'Parceiro': order.partner,
                '% de Desconto': order.valuePerc,
                'Usos': order.uses,
                'Status': validateCupomIsValid({ startDate: order.startDate, endDate: order.endDate, limit: order.limit, uses: order.uses }) ? 'Valido' : 'Invalido',
                'Valor de Desconto': order.value,
                'Início': order.startDate,
                'Término': order.endDate,
                'Limite': order.limit
            }
        })

        jsonExport(
            ordersForExport,
            {
                rowDelimiter: ';',
            },
            (err, csv) => {
                downloadCSV('\ufeff' + csv, `Pedidos ${new Date()}`) // download as 'posts.csv` file
            }
        )
    }



    return (
        <List {...props} exporter={exporter} title="Cupons" bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} filters={<CouponFilter />} perPage={25}>
            <Datagrid>
                <TextFieldRa source="name" label="Nome" />
                <TextFieldRa source="partner" label="Parceiro" />
                <TextFieldRa source="valuePerc" label="% de Desconto" />
                <TextFieldRa source="uses" label="Usos" />
                <FunctionField
                    label="Status"
                    render={record => record && validateCupomIsValid({ startDate: record.startDate, endDate: record.endDate, limit: record.limit, uses: record.uses }) ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
                />
                <TextFieldRa source="value" label="Valor de Desconto" />
                <FunctionField label="Início" render={record => record && record.startDate === '01/01/1970' ? 'Qualquer' : record.startDate} />
                <FunctionField label="Término" render={record => record && record.endDate === '01/01/1970' ? 'Qualquer' : record.endDate} />
                <FunctionField label="Limite" render={record => record && record.limit === 0 ? <AllInclusiveIcon /> : record.endDate} />
                <EditButton label="" />
                <DeleteButton label="" mutationMode={'pessimistic'} />
            </Datagrid>
        </List>
    )
}


// export const CouponEdit = (props) => {
//     return (
//         <Edit {...props}>
//             <SimpleForm redirect="list">
//                 <TextInput label="Nome" source="name" validate={validateName} />
//             </SimpleForm>
//         </Edit>
//     )
// }

const validateName = [required()]
const validateDate = [required()]
const validateLimit = [required()]

const validateUserCreation = (values) => {
    const errors = {}
    if (values.valueType === 1 && !values.valuePerc) {
        errors.valuePerc = 'Favor informar o percentual de desconto.'
    }

    if (values.valueType === 2 && !values.value) {
        errors.value = 'Favor informar o valor do desconto.'
    }

    return errors
}

export const AssistanceCouponEdit = (props) => {
    const { record } = useEditController(props)

    const [tagOptions, setTagOptions] = React.useState([])
    const [startDate, setStartDate] = React.useState(formatXmlDate(new Date()))
    const [checkIn, setCheckIn] = React.useState(formatXmlDate(new Date()))
    const [insurerOptions, setInsurerOptions] = React.useState([])

    useEffect(() => {
        (async () => {
            try {
                const result = await api.get(`admin/tags`)
                setTagOptions(result.data)
            } catch (error) {
                console.log("Erro ao consultar tags", error)
            }
            try {
                const result = await api.get(`admin/insurers`)
                let insurers = result.data.map(item => { return { id: item.id, name: item.name } })
                setInsurerOptions(insurers)
            } catch (error) {
                console.log('Erro ao consultar tags', error)
            }
        })()
    }, [])

    if (record)
        return (
            <Edit {...props}>
                <SimpleForm redirect="list" validate={validateUserCreation}>
                    <TextInput label="Nome" source="name" validate={validateName} />
                    <RadioButtonGroupInput label="Data da reserva (criação do pedido)" source="reservationDate" validate={validateLimit} helperText={false} choices={[
                        { id: 1, name: 'Qualquer' },
                        { id: 2, name: 'Período' }
                    ]} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.reservationDate === 2 &&
                            <>
                                <DateInput label="Início" source="startDate" validate={validateDate} inputProps={{
                                    min: formatXmlDate(new Date())
                                }} onChange={(e) => setStartDate(e.target.value)} />
                                <DateTimeInput label="Término" source="endDate" validate={validateDate} inputProps={{
                                    min: startDate + 'T00:00'
                                }} />
                            </>
                        }
                    </FormDataConsumer>
                    <RadioButtonGroupInput label="Data da viagem (período apólice)" source="travelDate" validate={validateLimit} helperText={false} choices={[
                        { id: 1, name: 'Qualquer' },
                        { id: 2, name: 'Período' }
                    ]} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.travelDate === 2 &&
                            <>
                                <DateInput label="Início" source="checkIn" validate={validateDate} inputProps={{
                                    min: formatXmlDate(new Date())
                                }} onChange={(e) => setCheckIn(e.target.value)} />
                                <DateInput label="Término" source="checkOut" validate={validateDate} inputProps={{
                                    min: checkIn
                                }} />
                            </>
                        }
                    </FormDataConsumer>

                    <RadioButtonGroupInput label="Tipo do desconto" source="valueType" validate={validateLimit} helperText={false} choices={[
                        { id: 1, name: 'Percentual' },
                        { id: 2, name: 'Valor Fixo' }
                    ]} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.valueType === 1 &&
                            <NumberInput label="Percentual de desconto" source="valuePerc" step={1} min='0' max='100' {...rest} />
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.valueType === 2 &&
                            <NumberInput label="Valor do desconto" source="value" min='1' {...rest} />
                        }
                    </FormDataConsumer>
                    <NumberInput label="Limite de uso (0 = ilimitado)" source="limit" initialValue={0} step={1} validate={validateLimit} />
                    <TextInput label="Parceiro" source="partner" validate={false} />
                    <RadioButtonGroupInput label="Forma de Pagamento" source="paymentType" validate={validateLimit} helperText={false} choices={[
                        { id: 'All', name: 'Todas' },
                        { id: 'CreditCard', name: 'Cartão de crédito' },
                        { id: 'Invoice', name: 'Faturado' },
                        { id: 'Transfer', name: 'Transferência (TED ou PIX)' },
                        { id: 'Slip', name: 'Boleto' },
                        { id: 'Link', name: 'Link' }
                    ]} />
                    {/* <SelectInput label="Vincular a Tag" source="tagId" choices={tagOptions} resettable /> */}
                    {/* <SelectInput label="Vincular a Seguradora" source="insurerId" choices={insurerOptions} resettable /> */}
                    <CheckboxGroupInput
                        label="Vincular a Seguradora (todas vazias = todas seguradoras)"
                        source="insurerId"
                        choices={insurerOptions}
                    />
                    <RadioButtonGroupInput label="Permissão" source="permission" validate={validateLimit} choices={[
                        { id: 'PUB', name: 'Pública' },
                        { id: 'PRI', name: 'Privada (apenas agentes logados)' },
                    ]} />
                </SimpleForm>
            </Edit>
        )
    else
        return null
}

export const AssistanceCouponCreate = (props) => {
    const [tagOptions, setTagOptions] = React.useState([])
    const [startDate, setStartDate] = React.useState(formatXmlDate(new Date()))
    const [checkIn, setCheckIn] = React.useState(formatXmlDate(new Date()))
    const [insurerOptions, setInsurerOptions] = React.useState([])

    useEffect(() => {
        (async () => {
            try {
                const result = await api.get(`admin/tags`)
                setTagOptions(result.data)
            } catch (error) {
                console.log("Erro ao consultar tags", error)
            }
            try {
                const result = await api.get(`admin/insurers`)
                let insurers = result.data.map(item => { return { id: item.id, name: item.name } })
                setInsurerOptions(insurers)
            } catch (error) {
                console.log('Erro ao consultar tags', error)
            }
        })()
    }, [])

    return (
        <Create {...props}>
            <SimpleForm redirect="list" validate={validateUserCreation}>
                <TextInput label="Nome" source="name" validate={validateName} />
                <RadioButtonGroupInput label="Data da reserva (criação do pedido)" source="reservationDate" validate={validateLimit} helperText={false} choices={[
                    { id: 1, name: 'Qualquer' },
                    { id: 2, name: 'Período' }
                ]} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.reservationDate === 2 &&
                        <>
                            <DateInput label="Início" source="startDate" validate={validateDate} inputProps={{
                                min: formatXmlDate(new Date())
                            }} onChange={(e) => setStartDate(e.target.value)} />
                            <DateTimeInput label="Término" source="endDate" validate={validateDate} inputProps={{
                                min: startDate + 'T00:00'
                            }} />
                        </>
                    }
                </FormDataConsumer>
                <RadioButtonGroupInput label="Data da viagem (período apólice)" source="travelDate" validate={validateLimit} helperText={false} choices={[
                    { id: 1, name: 'Qualquer' },
                    { id: 2, name: 'Período' }
                ]} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.travelDate === 2 &&
                        <>
                            <DateInput label="Início" source="checkIn" validate={validateDate} inputProps={{
                                min: formatXmlDate(new Date())
                            }} onChange={(e) => setCheckIn(e.target.value)} />
                            <DateInput label="Término" source="checkOut" validate={validateDate} inputProps={{
                                min: checkIn
                            }} />
                        </>
                    }
                </FormDataConsumer>

                <RadioButtonGroupInput label="Tipo do desconto" source="valueType" validate={validateLimit} helperText={false} choices={[
                    { id: 1, name: 'Percentual' },
                    { id: 2, name: 'Valor Fixo' }
                ]} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.valueType === 1 &&
                        <NumberInput label="Percentual de desconto" source="valuePerc" step={1} min='0' max='100' {...rest} />
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.valueType === 2 &&
                        <NumberInput label="Valor do desconto" source="value" min='1' {...rest} />
                    }
                </FormDataConsumer>
                <NumberInput label="Limite de uso (0 = ilimitado)" source="limit" initialValue={0} step={1} validate={validateLimit} />
                <TextInput label="Parceiro" source="partner" validate={false} />
                <RadioButtonGroupInput label="Forma de Pagamento" source="paymentType" validate={validateLimit} helperText={false} choices={[
                    { id: 'All', name: 'Todas' },
                    { id: 'CreditCard', name: 'Cartão de crédito' },
                    { id: 'Invoice', name: 'Faturado' },
                    { id: 'Transfer', name: 'Transferência (TED ou PIX)' },
                    { id: 'Slip', name: 'Boleto' },
                    { id: 'Link', name: 'Link' }
                ]} />
                {/* <SelectInput label="Vincular a tag" source="tagId" choices={tagOptions} resettable /> */}
                {/* <SelectInput label="Vincular a Seguradora" source="insurerId" choices={insurerOptions} resettable /> */}
                <CheckboxGroupInput
                    label="Vincular a Seguradora (todas vazias = todas seguradoras)"
                    source="insurerId"
                    choices={insurerOptions}
                />
                <RadioButtonGroupInput label="Permissão" source="permission" validate={validateLimit} choices={[
                    { id: 'PUB', name: 'Pública' },
                    { id: 'PRI', name: 'Privada (apenas agentes logados)' },
                ]} />
            </SimpleForm>
        </Create>
    )
}
