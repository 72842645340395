import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    useShowController,
    TextInput,
    ShowButton,
    TopToolbar,
    Filter,
    useCheckAuth,
    useNotify,
    useRefresh,
    DatagridBody,
    downloadCSV,
    SelectInput,
    DateInput,
    Edit,
    SimpleForm,
} from 'react-admin'
import {
    Button,
    Grid,
    TextField,
    Box,
    Paper,
    Tabs,
    Tab,
    Typography,
    AppBar,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip,
    TableCell,
    TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BackButton from '../../components/BackButton'
import api from '../../Services/Api'
import { formatDate, formatDateTime, formatXmlDate } from '../../functions'
import jsonExport from 'jsonexport/dist'
import formatter from '../../formatter'

const useStyles = makeStyles({
    root: {
        '& .MuiPaper-root': {
            marginTop: 10,
            padding: 10,
        },
        '& h4': {
            marginTop: 0,
        },
    },
    actions: {
        justifyContent: 'start', //botoes de ação na esquerda da tela
        '& button': {
            margin: 5,
        },
        '& a': {
            margin: 5,
        },
    },
    statusInput: {
        color: 'red',
    },
    headerCell: {
        padding: '10px',
    },
    rowCell: {
        backgroundColor: 'red',
        padding: '10px',
    },
})

const exporter = (orders) => {
    const ordersForExport = orders.map((order) => {
        const orderForExport = {
            Pedido: order.id,
            Reserva: order.reservationId,
            Situação: order.statusText,
            Entrada: order.checkIn,
            Saída: order.checkOut,
            'Valor Total': order.total.replace('.', ','),
            Agente: order.TravelAgent ? order.TravelAgent.email : '',

            //quarto 1
            'Tipo Quarto 1': order.Rooms[0] ? order.Rooms[0].name : '',
            'Responsável Quarto 1': order.Rooms[0]
                ? order.Rooms[0].ownerName
                : '',
            'E-Mail Responsável Quarto 1': order.Rooms[0]
                ? order.Rooms[0].ownerEmail
                : '',
            'Telefone Responsável Quarto 1': order.Rooms[0]
                ? order.Rooms[0].ownerPhone
                : '',
            'Adultos Quarto 1': order.Rooms[0] ? order.Rooms[0].guestAdt : '',
            'Crianças Quarto 1':
                order.Rooms[0] && order.Rooms[0].guestChdAges
                    ? order.Rooms[0].guestChdAges
                          .split(',')
                          .map((a) => a + ' anos')
                          .join()
                    : '',
            'Valor Quarto 1': order.Rooms[0]
                ? order.Rooms[0].total.replace('.', ',')
                : '',
            'Tipo Pagamento 1': order.Payments[0] ? order.Payments[0].type : '',
            //quarto 2
            'Tipo Quarto 2': order.Rooms[1] ? order.Rooms[1].name : '',
            'Responsável Quarto 2': order.Rooms[1]
                ? order.Rooms[1].ownerName
                : '',
            'E-Mail Responsável Quarto 2': order.Rooms[1]
                ? order.Rooms[1].ownerEmail
                : '',
            'Telefone Responsável Quarto 2': order.Rooms[1]
                ? order.Rooms[1].ownerPhone
                : '',
            'Adultos Quarto 2': order.Rooms[1] ? order.Rooms[1].guestAdt : '',
            'Crianças Quarto 2':
                order.Rooms[1] && order.Rooms[1].guestChdAges
                    ? order.Rooms[1].guestChdAges
                          .split(',')
                          .map((a) => a + ' anos')
                          .join()
                    : '',
            'Valor Quarto 2': order.Rooms[1]
                ? order.Rooms[1].total.replace('.', ',')
                : '',
            'Tipo Pagamento 2': order.Payments[1] ? order.Payments[1].type : '',

            //pagamento 1
            'Situação Pagamento 1': order.Payments[0]
                ? order.Payments[0].statusText
                : '',
            'Responsável Pagamento 1': order.Payments[0]
                ? order.Payments[0].cardHolder
                : '',
            'Cpf Pagamento 1': order.Payments[0] ? order.Payments[0].cpf : '',
            'Cartão Pagamento 1': order.Payments[0]
                ? order.Payments[0].cardNumber
                : '',
            'Bandeira Pagamento 1': order.Payments[0]
                ? order.Payments[0].cardBrand
                : '',
            'Parcelas Pagamento 1': order.Payments[0]
                ? order.Payments[0].installments
                : '',
            'Valor Pagamento 1': order.Payments[0]
                ? order.Payments[0].value.replace('.', ',')
                : '',
        }
        return orderForExport
    })

    jsonExport(
        ordersForExport,
        {
            rowDelimiter: ';',
        },
        (err, csv) => {
            downloadCSV('\ufeff' + csv, `Pedidos ${formatDateTime(new Date())}`) // download as 'posts.csv` file
        }
    )
}

const OrderFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome/CPF do cliente" source="q" alwaysOn />
        <TextInput label="Código Reserva" source="reservation" alwaysOn />
        <TextInput label="Hotel" source="hotel" alwaysOn />
        <TextInput label="Agente" source="travelAgent" alwaysOn />
        <SelectInput
            label="Situação"
            source="status"
            choices={[
                { id: 1, name: 'Aguardando Processamento' },
                { id: 2, name: 'Pagamento Pré-aprovado' },
                { id: 3, name: 'Pagamento Aprovado' },
                { id: 4, name: 'Reservado' },
                { id: 5, name: 'Concluído' },
                { id: 6, name: 'Pendente/Antifraude' },
                { id: 7, name: 'Pendente/Erro Pagamento' },
                { id: 8, name: 'Pendente/Erro Reserva' },
                { id: 9, name: 'Cancelado' },
                { id: 10, name: 'Pendente/Erro Pré-Pagamento' },
                { id: 11, name: 'Aguardando Pagamento' },
            ]}
            alwaysOn
        />
        <DateInput
            label="Data reserva"
            source="reservationAt"
            style={{ width: 180 }}
        />
    </Filter>
)

export const OrderList = (props) => {
    const classes = useStyles()

    const StatusField = ({ record, source }) => {
        return (
            <span
                className={
                    [6, 7, 8, 9, 10].includes(record.status) &&
                    classes.statusInput
                }
            >
                {record[source]}
            </span>
        )
    }

    const IdField = ({ record, source }) => {
        let messages = ''
        if (record.extraRequests) messages += 'Possui solicitação extra.'
        if (record.supplierPayment === 'Pré-Pagamento')
            messages += 'Pré-Pagamento ao Hotel.'

        if (messages)
            return (
                <Tooltip title={<div>{messages}</div>}>
                    <span>{record[source]}*</span>
                </Tooltip>
            )
        return (
            <span>
                {record[source]}
                {messages ? '*' : ''}
            </span>
        )
    }

    const MyDatagridRow = ({ record, resource, id, children, basePath }) => (
        <TableRow key={id}>
            {React.Children.map(children, (field) => {
                let style = { padding: '10px' }
                if (
                    field.props.source === 'checkIn' ||
                    field.props.source === 'checkOut' ||
                    field.props.source === 'id'
                )
                    style.width = '75px'

                if (field.props.source === 'totalText') {
                    style.textAlign = 'right'
                    style.width = '100px'
                    style.minWidth = '100px'
                }

                if (field.props.source === 'roomsCount') {
                    style.width = '50px'
                    style.textAlign = 'center'
                }

                if (field.props.source === 'reservationId')
                    style.width = '150px'

                if (field.props.source === 'statusText') style.width = '200px'

                return (
                    <TableCell
                        key={`${id}-${field.props.source}`}
                        style={style}
                    >
                        {React.cloneElement(field, {
                            record,
                            basePath,
                            resource,
                        })}
                    </TableCell>
                )
            })}
        </TableRow>
    )

    const MyDatagridBody = (props) => (
        <DatagridBody {...props} row={<MyDatagridRow />} />
    )
    const MyDatagrid = (props) => (
        <Datagrid {...props} body={<MyDatagridBody />} />
    )

    return (
        <List
            {...props}
            title="Lista de Pedidos"
            exporter={exporter}
            bulkActionButtons={false}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<OrderFilter />}
            perPage={25}
        >
            <MyDatagrid classes={{ headerCell: classes.headerCell }}>
                <IdField source="id" label="Pedido" />
                <TextFieldRa source="reservationId" label="Reserva" />
                <StatusField
                    source="statusText"
                    sortBy="status"
                    label="Situação"
                />
                <TextFieldRa
                    source="fraudPreventionStatusText"
                    sortBy="fraudPreventionStatus"
                    label="Anti Fraude"
                />
                <TextFieldRa source="Hotel.name" label="Hotel" />
                <TextFieldRa source="checkIn" label="Entrada" />
                <TextFieldRa source="checkOut" label="Saída" />
                <TextFieldRa source="roomsCount" label="Quarto(s)" />
                <TextFieldRa
                    source="totalText"
                    sortBy="total"
                    label="Total"
                    textAlign="right"
                />
                <TextFieldRa source="TravelAgent.email" label="Agente" />
                <ShowButton label="" />
            </MyDatagrid>
        </List>
    )
}

export const OrderEdit = (props) => {
    const location = useLocation()

    const redirect = (basePath, id, data) => `/admin/orders/${data.id}/show`

    const transform = data => ({
        ...data,
        type: location.state.type
    });

    const validateDates = (values) => {
        const errors = {};
        const checkIn = new Date(values.checkInEdit)
        const checkOut = new Date(values.checkOutEdit)
        
        if (checkOut <= checkIn) {
            errors.checkOutEdit = 'A data de saída deve ser maior que a data de entrada';
        }

        return errors
    };

    if (
        location.state &&
        location.state.type &&
        location.state.type === 'dates'
    )
        return (
            <Edit
                title="Alterar período da reserva"
                {...props}
                mutationMode="pessimistic"
                transform={transform}
            >
                <SimpleForm redirect={redirect} validate={validateDates}>
                    <DateInput label="Entrada" source="checkInEdit" inputProps={{
                                min: formatXmlDate(new Date())
                            }}/>
                    <DateInput label="Saída" source="checkOutEdit" inputProps={{
                                min: formatXmlDate(new Date())
                            }}/>
                </SimpleForm>
            </Edit>
        )
    else return null
}

export const OrderShow = ({ permissions, ...props }) => {
    const { record } = useShowController(props)

    const checkAuth = useCheckAuth()
    const handleCheckAuth = () => checkAuth()
    const notify = useNotify()
    const refresh = useRefresh()

    const classes = useStyles()

    const [value, setValue] = React.useState(0)
    const [forceBookingModalOpen, setforceBookingModalOpen] =
        React.useState(false)
    const [cancelOrderModalOpen, setCancelOrderModalOpen] =
        React.useState(false)
    const [sendVoucherModalOpen, setSendVoucherModalOpen] =
        React.useState(false)
    const [emailVoucher, setEmailVoucher] = React.useState(null)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        )
    }

    const sendVoucher = async (orderId, email) => {
        try {
            await handleCheckAuth()
            const result = await api.post(`/voucher/${orderId}`, {
                email: email,
            })
            if (result.data.success) {
                notify('Voucher enviado com sucesso!', 'success')
                refresh()
            } else throw new Error()
        } catch (error) {
            console.log('erro ao reenviar voucher', error)
            notify('Erro ao enviar voucher!', 'warning')
            refresh()
        }
    }

    const cancelOrder = async (orderId) => {
        try {
            await handleCheckAuth()
            const result = await api.post('/hotel/cancel', { orderId })
            if (result.data.success) {
                notify('Reserva cancelada com sucesso!', 'success')
                refresh()
            }
        } catch (error) {
            console.log('erro ao cancelar reserva')
            notify('Erro ao cancelar reserva!', 'warning')
            refresh()
        }
    }

    const approveManually = async (orderId) => {
        try {
            await handleCheckAuth()
            const result = await api.post('/approveManually', { orderId })
            if (result.data.success) {
                notify('Reserva aprovada manualmente!', 'success')
                refresh()
            } else throw new Error()
        } catch (error) {
            console.log('erro ao aprovar manualmente', error)
            notify(`Erro ao aprovar manualmente a reserva! ${JSON.stringify(error)}`, 'warning')
            refresh()
        }
    }

    const forceBookingModal = async () => {
        setforceBookingModalOpen(true)
    }

    const sendVoucherModal = async () => {
        setSendVoucherModalOpen(true)
    }

    const cancelOrderModal = async () => {
        setCancelOrderModalOpen(true)
    }

    if (record) {
        return (
            <div className={classes.root}>
                <TopToolbar className={classes.actions}>
                    <BackButton />
                    {[6, 11].includes(record.status) && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => forceBookingModal()}
                        >
                            Aprovar Manualmente
                        </Button>
                    )}
                    {/* só permite reenviar voucher e cancelar se o pedido estiver reservado ou concluído */}
                    {[4, 5].includes(record.status) && (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => sendVoucherModal()}
                            >
                                Reenviar Voucher
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: 'Red' }}
                                onClick={() => cancelOrderModal()}
                            >
                                Cancelar Reserva
                            </Button>
                        </>
                    )}
                    {permissions.includes('admin') && (
                        <Button
                            component={Link}
                            variant="contained"
                            to={{
                                pathname: `/orders/${record.id}`,
                                state: { type: 'dates' },
                            }}
                            color="primary"
                        >
                            Alterar Período
                        </Button>
                    )}
                </TopToolbar>
                <AppBar position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Informações" id="info" />
                        <Tab label="Histórico" id="history" />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Paper>
                        <h4>Informações do Pedido</h4>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="id"
                                    label="Pedido"
                                    value={record.id}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{
                                        readOnly: true,
                                        className:
                                            [6, 7, 8, 9, 10].includes(
                                                record.status
                                            ) ? classes.statusInput : null,
                                    }}
                                    id="statusText"
                                    label="Situação"
                                    value={record.statusText}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="fraudPreventionStatusText"
                                    label="Anti Fraude"
                                    value={record.fraudPreventionStatusText}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="hotelName"
                                    label="Hotel"
                                    value={
                                        record.Hotel ? record.Hotel.name : ''
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    InputLabelProps={{ shrink: true }}
                                    id="checkIn"
                                    label="Entrada"
                                    value={record.checkIn}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    InputLabelProps={{ shrink: true }}
                                    id="checkOut"
                                    label="Saída"
                                    value={record.checkOut}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="reservationId"
                                    label="Reserva"
                                    value={record.reservationId}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="totalOrder"
                                    label="Total"
                                    value={formatter.format(record.total)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="travelAgent"
                                    label="Agente"
                                    value={
                                        record.TravelAgent
                                            ? record.TravelAgent.email
                                            : ''
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="extraRequests"
                                    label="Solicitação Adicional"
                                    value={record.extraRequests}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="supplierPayment"
                                    label="Pagamento Hotel"
                                    value={record.supplierPayment}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="coupon"
                                    label="Cupom de Desconto"
                                    value={record.coupon}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper>
                        <h4>Pagamentos</h4>
                        {record.Payments &&
                            record.Payments.map((payment, index) => {
                                switch (payment.type) {
                                    case 'CreditCard':
                                        return (
                                            <Paper>
                                                <h4>Cartão {index + 1}</h4>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={12} md={4}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardHolder"
                                                            label="Pagador"
                                                            value={
                                                                payment.cardHolder
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={4}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardNumber"
                                                            label="Cartão"
                                                            value={
                                                                payment.cardNumber
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardExpiration"
                                                            label="Vencimento"
                                                            value={
                                                                payment.cardExpiration
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="statusText"
                                                            label="Situação"
                                                            value={
                                                                payment.statusText
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={12} md={4}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardBrand"
                                                            label="Bandeira"
                                                            value={
                                                                payment.cardBrand
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardAuthorization"
                                                            label="Autorização"
                                                            value={
                                                                payment.cardAuthorization
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardValue"
                                                            label="Valor"
                                                            value={
                                                                formatter.format(payment.value)
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={4}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="paymentId"
                                                            label="ID Pagamento Cielo"
                                                            value={
                                                                payment.cardPaymentId
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    case 'Invoice':
                                        return (
                                            <Paper>
                                                <h4>Faturado</h4>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardValue"
                                                            label="Valor"
                                                            value={
                                                                formatter.format(payment.value)
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    case 'Transfer':
                                        return (
                                            <Paper>
                                                <h4>Transferência</h4>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardValue"
                                                            label="Valor"
                                                            value={
                                                                formatter.format(payment.value)
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    default:
                                        return null
                                }
                            })}
                    </Paper>                    
                    {record.Rooms &&
                        record.Rooms.map((room, roomIndex) => (
                            <Paper key={roomIndex}>
                                <div style={{display: "flex", alignItems: 'baseline'}}>
                                    <h4 style={{margin: '0px 10px 0px 0px'}}>Quarto {roomIndex + 1}</h4>
                                    {permissions.includes('admin') && (
                                        <Button
                                            component={Link}
                                            size="small"
                                            variant="contained"
                                            to={{
                                                pathname: `/rooms/${room.id}`,
                                                state: {
                                                    roomNumber: roomIndex + 1,
                                                },
                                            }}
                                            color="primary"
                                            style={{margin: '0px 0px 15px 0px'}}
                                        >
                                            Alterar Hóspede
                                        </Button>
                                    )}
                                </div>
                                <Grid container spacing={3}>
                                    <Grid item sm={12} md={4}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="roomName"
                                            label="Tipo"
                                            value={room.name}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="adt"
                                            label="Adulto(s)"
                                            value={room.guestAdt}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    {room.guestChdAges &&
                                        room.guestChdAges
                                            .split(',')
                                            .map((chdAge, chdIndex) => (
                                                <Grid item xs>
                                                    <TextField
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        id="chd"
                                                        label={
                                                            'Criança ' +
                                                            (chdIndex + 1)
                                                        }
                                                        value={
                                                            chdAge + ' Ano(s)'
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            ))}
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item sm={12} md={4}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="owner"
                                            label="Responsável"
                                            value={room.ownerName}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            id="ownerEmail"
                                            label="Email"
                                            value={room.ownerEmail}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            id="ownerCpf"
                                            label="Cpf"
                                            value={room.ownerCpf}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="ownerPhone"
                                            label="Telefone"
                                            value={room.ownerPhone}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            id="ownerDob"
                                            label="Nascimento"
                                            value={formatDate(room.ownerDob)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="totalFare"
                                            label="Total Diárias"
                                            value={formatter.format(room.totalFare)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="totalTaxes"
                                            label="Total Taxas"
                                            value={formatter.format(room.totalTaxes)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="totalMarkup"
                                            label="Total Markup"
                                            value={formatter.format(room.totalMarkup)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="commission"
                                            label="Comissão"
                                            value={formatter.format(room.commission)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="customerDiscount"
                                            label="Desconto Cliente"
                                            value={formatter.format(room.customerDiscount)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="total"
                                            label="Total Geral"
                                            value={
                                                formatter.format(room.total -
                                                room.customerDiscount)
                                            }
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Paper>
                        <h4>Histórico do Pedido</h4>
                        {record.OrderHistories &&
                            record.OrderHistories.map((history, index) => (
                                <Grid key={index} container spacing={3}>
                                    <Grid item sm={12} md={4}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="action"
                                            label="Ação"
                                            value={history.action}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="user"
                                            label="Usuário"
                                            value={history.user}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="actionDate"
                                            label="Data"
                                            value={formatDateTime(
                                                history.createdAt
                                            )}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                    </Paper>
                </TabPanel>

                <Dialog // aprovar manualmente
                    open={forceBookingModalOpen}
                    onClose={() => setforceBookingModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja efetuar a reserva
                            manualmente?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                approveManually(record.id)
                                setforceBookingModalOpen(false)
                            }}
                            color="primary"
                        >
                            Sim
                        </Button>
                        <Button
                            onClick={() => setforceBookingModalOpen(false)}
                            color="primary"
                            autoFocus
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // cancelar
                    open={cancelOrderModalOpen}
                    onClose={() => setCancelOrderModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja cancelar a reserva?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                cancelOrder(record.id)
                                setCancelOrderModalOpen(false)
                            }}
                            color="primary"
                        >
                            Sim
                        </Button>
                        <Button
                            onClick={() => setCancelOrderModalOpen(false)}
                            color="primary"
                            autoFocus
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // reenviar voucher
                    open={sendVoucherModalOpen}
                    onClose={() => setSendVoucherModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Deseja reenviar o voucher?
                        </DialogContentText>
                        <TextField
                            id="emailVoucher"
                            label="E-Mail"
                            value={emailVoucher}
                            onChange={(e) => setEmailVoucher(e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                sendVoucher(record.id, emailVoucher)
                                setSendVoucherModalOpen(false)
                            }}
                            color="primary"
                        >
                            Enviar
                        </Button>
                        <Button
                            onClick={() => setSendVoucherModalOpen(false)}
                            color="primary"
                            autoFocus
                        >
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    } else return null
}
