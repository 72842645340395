import { API_URL } from './../consts';

const auth = (params) => {

    const request = new Request( API_URL + '/me', {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json', 'authorization': 'Bearer ' + params.match.params.token }),
    });
    fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                return Promise.reject()
            }
            return response.json();
        })
        .then(auth => {
            const newAuth = {}
            newAuth.user = { ...auth }
            newAuth.token = JSON.parse(localStorage.getItem('auth')).token
            localStorage.setItem('auth', JSON.stringify(newAuth));

            const { user } = JSON.parse(localStorage.getItem('auth'));
            return Promise.resolve(user.role);
        })
        .catch(() => {
            return Promise.reject()
        });

    // if (params.match.params.token) {
    //     api.defaults.headers.Authorization = `Bearer ${params.match.params.token}`
    //     localStorage.setItem('auth', JSON.stringify({ token: params.match.params.token }));
    //     return null
    // }
};

export default auth;
