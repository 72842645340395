import React from 'react'
import { useEffect } from 'react'
import {
    List, Datagrid, TextField as TextFieldRa, EditButton, TextInput, SimpleForm, Edit, Filter, SearchInput, NumberInput, RadioButtonGroupInput,
    DeleteButton, required, Create, DateInput, SelectInput, useEditController, FormDataConsumer, DateTimeInput
} from 'react-admin'
import Icon from '@material-ui/icons/LocalActivity'
import api from "../../Services/Api"
import { formatXmlDate } from '../../functions'
export const CouponIcon = Icon

const CouponFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
)
export const CouponList = ({ permissions, ...props }) => {
    return (
        <List {...props} exporter={false} title="Cupons" bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} filters={<CouponFilter />} perPage={25}>
            <Datagrid>
                <TextFieldRa source="name" label="Nome" />
                <TextFieldRa source="valuePerc" label="% de Desconto" />
                <TextFieldRa source="value" label="Valor de Desconto" />
                <TextFieldRa source="startDate" label="Início" />
                <TextFieldRa source="endDate" label="Término" />
                <TextFieldRa source="limit" label="Limite" />
                <EditButton label="" />
                <DeleteButton label="" />
            </Datagrid>
        </List>
    )
}


// export const CouponEdit = (props) => {
//     return (
//         <Edit {...props}>
//             <SimpleForm redirect="list">
//                 <TextInput label="Nome" source="name" validate={validateName} />
//             </SimpleForm>
//         </Edit>
//     )
// }

const validateName = [required()]
const validateDate = [required()]
const validateLimit = [required()]

const validateUserCreation = (values) => {
    const errors = {}
    if (values.valueType === 1 && !values.valuePerc) {
        errors.valuePerc = 'Favor informar o percentual de desconto.'
    }

    if (values.valueType === 2 && !values.value) {
        errors.value = 'Favor informar o valor do desconto.'
    }

    return errors
}

export const CouponEdit = (props) => {
    const { record } = useEditController(props)

    const [tagOptions, setTagOptions] = React.useState([])
    const [startDate, setStartDate] = React.useState(formatXmlDate(new Date()))
    const [checkIn, setCheckIn] = React.useState(formatXmlDate(new Date()))

    useEffect(() => {
        (async () => {
            try {
                const result = await api.get(`admin/tags`)
                setTagOptions(result.data)
            } catch (error) {
                console.log("Erro ao consultar tags", error)
            }
        })()
    }, [])

    if (record)
        return (
            <Edit {...props}>
                <SimpleForm redirect="list" validate={validateUserCreation}>
                    <TextInput label="Nome" source="name" validate={validateName} />
                    <RadioButtonGroupInput label="Data da reserva" source="reservationDate" validate={validateLimit} helperText={false} choices={[
                        { id: 1, name: 'Qualquer' },
                        { id: 2, name: 'Período' }
                    ]} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.reservationDate === 2 &&
                            <>
                                <DateInput label="Início" source="startDate" validate={validateDate} inputProps={{
                                    min: formatXmlDate(new Date())
                                }} onChange={(e) => setStartDate(e.target.value)} />
                                <DateTimeInput label="Término" source="endDate" validate={validateDate} inputProps={{
                                    min: startDate + 'T00:00'
                                }} />
                            </>
                        }
                    </FormDataConsumer>
                    <RadioButtonGroupInput label="Data da viagem" source="travelDate" validate={validateLimit} helperText={false} choices={[
                        { id: 1, name: 'Qualquer' },
                        { id: 2, name: 'Período' }
                    ]} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.travelDate === 2 &&
                            <>
                                <DateInput label="Início" source="checkIn" validate={validateDate} inputProps={{
                                    min: formatXmlDate(new Date())
                                }} onChange={(e) => setCheckIn(e.target.value)} />
                                <DateInput label="Término" source="checkOut" validate={validateDate} inputProps={{
                                    min: checkIn
                                }} />
                            </>
                        }
                    </FormDataConsumer>

                    <RadioButtonGroupInput label="Tipo do desconto" source="valueType" validate={validateLimit} helperText={false} choices={[
                        { id: 1, name: 'Percentual' },
                        { id: 2, name: 'Valor Fixo' }
                    ]} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.valueType === 1 &&
                            <NumberInput label="Percentual de desconto" source="valuePerc" step={1} min='0' max='100' {...rest} />
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.valueType === 2 &&
                            <NumberInput label="Valor do desconto" source="value" min='1' {...rest} />
                        }
                    </FormDataConsumer>
                    <NumberInput label="Limite de uso (0 = ilimitado)" source="limit" initialValue={0} step={1} validate={validateLimit} />
                    <RadioButtonGroupInput label="Forma de Pagamento" source="paymentType" validate={validateLimit} helperText={false} choices={[
                        { id: 'All', name: 'Todas' },
                        { id: 'CreditCard', name: 'Cartão de crédito' },
                        { id: 'Invoice', name: 'Faturado' },
                        { id: 'Transfer', name: 'Transferência' }
                    ]} />
                    <SelectInput label="Vincular a tag" source="tagId" choices={tagOptions} resettable />
                </SimpleForm>
            </Edit>
        )
    else
        return null
}

export const CouponCreate = (props) => {
    const [tagOptions, setTagOptions] = React.useState([])
    const [startDate, setStartDate] = React.useState(formatXmlDate(new Date()))
    const [checkIn, setCheckIn] = React.useState(formatXmlDate(new Date()))

    useEffect(() => {
        (async () => {
            try {
                const result = await api.get(`admin/tags`)
                setTagOptions(result.data)
            } catch (error) {
                console.log("Erro ao consultar tags", error)
            }
        })()
    }, [])

    return (
        <Create {...props}>
            <SimpleForm redirect="list" validate={validateUserCreation}>
                <TextInput label="Nome" source="name" validate={validateName} />
                <RadioButtonGroupInput label="Data da reserva" source="reservationDate" validate={validateLimit} helperText={false} choices={[
                    { id: 1, name: 'Qualquer' },
                    { id: 2, name: 'Período' }
                ]} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.reservationDate === 2 &&
                        <>
                            <DateInput label="Início" source="startDate" validate={validateDate} inputProps={{
                                min: formatXmlDate(new Date())
                            }} onChange={(e) => setStartDate(e.target.value)} />
                            <DateTimeInput label="Término" source="endDate" validate={validateDate} inputProps={{
                                min: startDate + 'T00:00'
                            }} />
                        </>
                    }
                </FormDataConsumer>
                <RadioButtonGroupInput label="Data da viagem" source="travelDate" validate={validateLimit} helperText={false} choices={[
                    { id: 1, name: 'Qualquer' },
                    { id: 2, name: 'Período' }
                ]} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.travelDate === 2 &&
                        <>
                            <DateInput label="Início" source="checkIn" validate={validateDate} inputProps={{
                                min: formatXmlDate(new Date())
                            }} onChange={(e) => setCheckIn(e.target.value)} />
                            <DateInput label="Término" source="checkOut" validate={validateDate} inputProps={{
                                min: checkIn
                            }} />
                        </>
                    }
                </FormDataConsumer>

                <RadioButtonGroupInput label="Tipo do desconto" source="valueType" validate={validateLimit} helperText={false} choices={[
                    { id: 1, name: 'Percentual' },
                    { id: 2, name: 'Valor Fixo' }
                ]} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.valueType === 1 &&
                        <NumberInput label="Percentual de desconto" source="valuePerc" step={1} min='0' max='100' {...rest} />
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.valueType === 2 &&
                        <NumberInput label="Valor do desconto" source="value" min='1' {...rest} />
                    }
                </FormDataConsumer>
                <NumberInput label="Limite de uso (0 = ilimitado)" source="limit" initialValue={0} step={1} validate={validateLimit} />
                <RadioButtonGroupInput label="Forma de Pagamento" source="paymentType" validate={validateLimit} helperText={false} choices={[
                    { id: 'All', name: 'Todas' },
                    { id: 'CreditCard', name: 'Cartão de crédito' },
                    { id: 'Invoice', name: 'Faturado' },
                    { id: 'Transfer', name: 'Transferência' }
                ]} />
                <SelectInput label="Vincular a tag" source="tagId" choices={tagOptions} resettable />
            </SimpleForm>
        </Create>
    )
}
