import React from 'react'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    EditButton,
    TextInput,
    SimpleForm,
    Edit,
    DeleteButton,
    required,
    Create,
} from 'react-admin'
import Icon from '@mui/icons-material/RequestPage';

export const ExchangeRatesIcon = Icon

const currencyBRL = (value) => {
    value = value.replace('.', '')
    return value
}

export const ExchangeRatesList = ({ permissions, ...props }) => {
    return (
        <List
            {...props}
            exporter={false}
            title="Seguradoras"
            bulkActionButtons={false}
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={25}
        >
            <Datagrid>
                <TextFieldRa source="value" label="Câmbio" />
                <TextFieldRa source="currency" label="Moeda" />
                <TextFieldRa source="insurerId" label="Seguradora (ID)" />
                <TextFieldRa source="createdAt" label="Data Criação" />
                <EditButton label="" />
                <DeleteButton label="" />
            </Datagrid>
        </List>
    )
}

export const ExchangeRatesEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <TextInput
                    label="Câmbio"
                    source="value"
                    validate={[required()]}
                    parse={currencyBRL}
                />
                <TextInput
                    label="Moeda"
                    source="currency"
                    validate={[required()]}
                />
            </SimpleForm>
        </Edit>
    )
}

export const ExchangeRatesCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput
                    label="Câmbio"
                    source="value"
                    validate={[required()]}
                    parse={currencyBRL}
                />
                <TextInput
                    label="Moeda"
                    source="currency"
                    validate={[required()]}
                />
            </SimpleForm>
        </Create>
    )
}
