import React from 'react'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    EditButton,
    TextInput,
    SimpleForm,
    Edit,
    DeleteButton,
    required,
    Create,
    BooleanInput,
    BooleanField,
    RadioButtonGroupInput,
    FormDataConsumer,
    NumberInput,
    SelectInput,
} from 'react-admin'

import Icon from '@mui/icons-material/CreditCard'

export const PaymentTypesIcon = Icon

export const PaymentTypesList = ({ permissions, ...props }) => {
    return (
        <List
            {...props}
            exporter={false}
            title="Seguradoras"
            bulkActionButtons={false}
            sort={{ field: 'brand', order: 'ASC' }}
            perPage={25}
        >
            <Datagrid>
                <TextFieldRa source="type" label="Tipo" />
                <TextFieldRa source="brand" label="Bandeira" />
                <TextFieldRa source="maxInstallments" label="Máximo Parcelas" />
                <TextFieldRa
                    source="minInstallmentValue"
                    label="Valor Mínimo Parcela"
                />
                <BooleanField source="active" label="Ativo" />
                <EditButton label="" />
                <DeleteButton label="" />
            </Datagrid>
        </List>
    )
}

export const PaymentTypesEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <RadioButtonGroupInput
                    label="Tipo"
                    source="type"
                    helperText={false}
                    choices={[
                        { id: 'Pix', name: 'Pix' },
                        { id: 'Boleto', name: 'Boleto' },
                        { id: 'Cartão', name: 'Cartão' },
                        { id: 'Transferência', name: 'Transferência' },
                    ]}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.type === 'Cartão' && (
                            <SelectInput
                                source="brand"
                                label="Bandeira"
                                choices={[
                                    { id: 'Master', name: 'Master' },
                                    { id: 'Visa', name: 'Visa' },
                                    { id: 'Elo', name: 'Elo' },
                                    { id: 'Diners', name: 'Diners' },
                                    { id: 'Amex', name: 'Amex' },
                                ]}
                            />
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.type === 'Cartão' && (
                            <TextInput
                                source="maxInstallments"
                                label="Máximo Parcelas"
                            />
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.type === 'Cartão' && (
                            <TextInput
                                source="minInstallmentValue"
                                label="Valor Mínimo Parcela"
                            />
                        )
                    }
                </FormDataConsumer>
                {Array.from({length: 12}, (_, i) => i + 1).map((a) => (
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.type === 'Cartão' && (
                                <TextInput
                                    source={`tax${a}x`}
                                    label={`Taxa ${a}x`}
                                />
                            )
                        }
                    </FormDataConsumer>
                ))}
                <BooleanInput label="Ativo" source="active" />
            </SimpleForm>
        </Edit>
    )
}

export const PaymentTypesCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <RadioButtonGroupInput
                    label="Tipo"
                    source="type"
                    helperText={false}
                    choices={[
                        { id: 'Pix', name: 'Pix' },
                        { id: 'Boleto', name: 'Boleto' },
                        { id: 'Cartão', name: 'Cartão' },
                        { id: 'Transferência', name: 'Transferência' },
                    ]}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.type === 'Cartão' && (
                            <SelectInput
                                source="brand"
                                label="Bandeira"
                                choices={[
                                    { id: 'Master', name: 'Master' },
                                    { id: 'Visa', name: 'Visa' },
                                    { id: 'Elo', name: 'Elo' },
                                    { id: 'Diners', name: 'Diners' },
                                    { id: 'Amex', name: 'Amex' },
                                ]}
                            />
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.type === 'Cartão' && (
                            <TextInput
                                source="maxInstallments"
                                label="Máximo Parcelas"
                            />
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.type === 'Cartão' && (
                            <TextInput
                                source="minInstallmentValue"
                                label="Valor Mínimo Parcela"
                            />
                        )
                    }
                </FormDataConsumer>

                <BooleanInput label="Ativo" source="active" />
            </SimpleForm>
        </Create>
    )
}
