import React from 'react'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    EditButton,
    TextInput,
    SimpleForm,
    SelectInput,
    BooleanInput,
    BooleanField,
    Edit,
    DeleteButton,
    required,
    Create,
    TabbedShowLayout,
    Tab as TabRa,
    FormTab,
    TabbedForm,
} from 'react-admin'
import Icon from '@material-ui/icons/AttachMoney'

export const InsurersIcon = Icon

export const InsurersList = ({ permissions, ...props }) => {
    return (
        <List
            {...props}
            exporter={false}
            title="Seguradoras"
            bulkActionButtons={false}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={25}
        >
            <Datagrid>
                <TextFieldRa source="name" label="Nome" />
                <TextFieldRa source="code" label="Código" />
                <BooleanField source="active" label="Seguradora ativa?" />
                <EditButton label="" />
                <DeleteButton label="" mutationMode={'pessimistic'} />
            </Datagrid>
        </List>
    )
}

export const InsurersEdit = (props) => {
    return (
        <Edit {...props}>
            <TabbedForm>
                <FormTab label="Geral" redirect="list">
                    <TextInput
                        label="Nome"
                        source="name"
                        validate={[required()]}
                    />
                    <TextInput
                        label="Código"
                        source="code"
                        validate={[required()]}
                    />
                    <TextInput
                        label="Código Gate"
                        source="backOfficeCode"
                        validate={[required()]}
                    />
                    <TextInput label="Endereço" source="address" />
                    <TextInput label="Número" source="number" />
                    <TextInput label="Complemento" source="complement" />
                    <TextInput label="Bairro" source="district" />
                    <TextInput label="Cidade" source="city" />
                    <TextInput label="Estado" source="state" />
                    <TextInput label="CEP" source="cep" />
                    <TextInput label="Telefone" source="phone" />
                    <TextInput label="CNPJ" source="cnpj" />
                    <TextInput label="URL Condições Gerais" source="generalConditionsUrl" />
                    <TextInput label="logo" source="logo" />
                    <TextInput label="logoSmall" source="logoSmall" />
                    <SelectInput label="Tipo de Emissão" source="issueType" choices={[
                        { id: 'Individual', name: 'Individual' },
                        { id: 'All', name: 'Combinado' },
                    ]} />
                    <SelectInput label="Contato de Emergência" source="emergencyInfoRequired" choices={[
                        { id: true, name: 'Obrigatório' },
                        { id: false, name: 'Opcional' },
                    ]} />
                    <BooleanInput label="Seguradora ativa para busca?" source="active" />
                </FormTab>
                <FormTab label="Contato Adm." redirect="list">
                    <TextInput label="Contato" source="contactName" />
                    <TextInput label="Telefone Contato" source="contactPhone" />
                    <TextInput label="WhatsApp" source="whatsAppNumber" />
                    <TextInput label="E-Mail" source="email" />
                </FormTab>
                <FormTab label="Contato Emergencial" redirect="list">
                    <TextInput
                        label="Telefone Brasil"
                        source="contactPhoneEmBrasil"
                    />
                    <TextInput
                        label="Telefone Exterior"
                        source="contactPhoneEmExt"
                    />
                    <TextInput label="WhatsApp" source="whatsAppNumberEm" />
                    <TextInput label="E-Mail" source="emailEm" />
                    <TextInput label="Aplicativo Android" source="appAndroid" />
                    <TextInput label="Aplicativo IOS" source="appIOS" />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export const InsurersCreate = (props) => {
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label="Geral" redirect="list">
                    <TextInput
                        label="Nome"
                        source="name"
                        validate={[required()]}
                    />
                    <TextInput
                        label="Código"
                        source="code"
                        validate={[required()]}
                    />
                    <TextInput
                        label="Código Gate"
                        source="backOfficeCode"
                        validate={[required()]}
                    />
                    <TextInput label="Endereço" source="address" />
                    <TextInput label="Número" source="number" />
                    <TextInput label="Complemento" source="complement" />
                    <TextInput label="Bairro" source="district" />
                    <TextInput label="Cidade" source="city" />
                    <TextInput label="Estado" source="state" />
                    <TextInput label="CEP" source="cep" />
                    <TextInput label="Telefone" source="phone" />
                    <TextInput label="CNPJ" source="cnpj" />
                    <TextInput label="URL Condições Gerais" source="generalConditionsUrl" />
                    <BooleanInput label="Seguradora ativa para busca?" source="active" />
                </FormTab>
                <FormTab label="Contato Adm." redirect="list">
                    <TextInput label="Contato" source="contactName" />
                    <TextInput label="Telefone Contato" source="contactPhone" />
                    <TextInput label="WhatsApp" source="whatsAppNumber" />
                    <TextInput label="E-Mail" source="email" />
                </FormTab>
                <FormTab label="Contato Emergencial" redirect="list">
                    <TextInput
                        label="Telefone Brasil"
                        source="contactPhoneEmBrasil"
                    />
                    <TextInput
                        label="Telefone Exterior"
                        source="contactPhoneEmExt"
                    />
                    <TextInput label="WhatsApp" source="whatsAppNumberEm" />
                    <TextInput label="E-Mail" source="emailEm" />
                    <TextInput label="Aplicativo Android" source="appAndroid" />
                    <TextInput label="Aplicativo IOS" source="appIOS" />
                </FormTab>
            </TabbedForm>
        </Create>
    )
}
