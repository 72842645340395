import React from 'react'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import api from '../../../Services/Api'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    EditButton,
    TextInput,
    SimpleForm,
    Edit,
    Filter,
    SearchInput,
    NumberInput,
    DeleteButton,
    required,
    Create,
    SelectInput,
} from 'react-admin'
import Icon from '@material-ui/icons/Label'

export const ProductDestinationsIcon = Icon

const ProductDestinationsFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
)
export const ProductDestinationsList = ({ permissions, ...props }) => {
    return (
        <List
            {...props}
            exporter={false}
            title="ProductDestinationss"
            bulkActionButtons={false}
            sort={{ field: 'name', order: 'ASC' }}
            filters={<ProductDestinationsFilter />}
            perPage={25}
        >
            <Datagrid>
                <TextFieldRa source="name" label="Nome" />
                <EditButton label="" />
                <DeleteButton label="" />
            </Datagrid>
        </List>
    )
}

export const ProductDestinationsCreate = (props) => {
    const [destinationOptions, setDestinationOptions] = React.useState([])

    useEffect(() => {
        ;(async () => {
            try {
                const result = await api.get(
                    `admin/destinations?_order=ASC&_sort=name`
                )
                setDestinationOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar coberturas', error)
            }
        })()
    }, [])

    const redirect = (basePath, id, data) =>
        `/admin/products/${data.productId}/show/4`

    const location = useLocation()
    const transform = (data) => ({
        ...data,
        productId: location.state.productId,
    })

    return (
        <Create {...props} mutationMode="pessimistic" transform={transform}>
            <SimpleForm redirect={redirect}>
                <SelectInput
                    label="Destino"
                    source="destinationId"
                    choices={destinationOptions}
                    resettable
                    validate={[required()]}
                />
            </SimpleForm>
        </Create>
    )
}
