import React from 'react'
import { useEffect } from 'react'
import api from '../../../Services/Api'
import { Link } from 'react-router-dom'
import {
    List,
    Show,
    Datagrid,
    TextField as TextFieldRa,
    BooleanInput,
    EditButton,
    TextInput,
    SimpleForm,
    TabbedForm,
    FormTab,
    Tab as TabRa,
    TabbedShowLayout,
    Edit,
    DeleteButton,
    required,
    Create,
    SelectInput,
    Filter,
    SearchInput,
    ReferenceManyField,
    TextField,
    TopToolbar,
    useCheckAuth,
    useRefresh,
    useEditController,
    useShowController,
    useRedirect,
    SelectField,
    BooleanField,
    ShowButton,
    ListButton,
    NumberInput,
    NumberField,
    Pagination,
} from 'react-admin'
import {

    Dialog,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core'
import Icon from '@material-ui/icons/LibraryAdd'
import { makeStyles } from '@material-ui/core/styles'

export const ProductsIcon = Icon

const useStyles = makeStyles({
    root: {
        '& .MuiPaper-root': {
            marginTop: 10,
            padding: 10,
        },
        '& h4': {
            marginTop: 0,
        },
    },
    formControl: {
        minWidth: 250,
    },
    actions: {
        justifyContent: 'start', //botoes de ação na esquerda da tela
        '& button': {
            margin: 5,
        },
        paddingTop: '0px',
    },
    card: {
        maxWidth: 345,
    },
    cardActions: {
        cursor: 'pointer',
        padding: '0px',
    },
    image: {
        height: 140,
    },
    default: {
        backgroundColor: '#3f51b5',
        color: 'white',
    },
    active: {
        backgroundColor: 'green',
        color: 'white',
    },
    inactive: {
        backgroundColor: 'red',
        color: 'white',
    },
    own: {
        backgroundColor: '#00aeef',
        color: 'white',
    },
    button: {
        marginTop: '0px!important',
        marginBottom: '0px!important',
    },
    list: {
        padding: '20px',
    },
    listItem: {
        margin: '0px',
    },
    alertText: {
        color: 'red',
    },
})

export const ProductFilter = (props) => {
    const [insurerOptions, setInsurerOptions] = React.useState([])
    const categories = [
        { id: "EC", name: "Econômico" },
        { id: "IT", name: "Intermediário" },
        { id: "PR", name: "Premium" }]

    useEffect(() => {
        ; (async () => {
            try {
                const result = await api.get(`admin/insurers`)
                setInsurerOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar insurers', error)
            }
        })()
    }, [])

    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <SelectInput
                label="Seguradora"
                source="insurerId"
                choices={insurerOptions}
                alwaysOn
            />
            <SelectInput
                label="Mostrar para Cliente?"
                source="displayToCustomers"
                choices={[
                    { id: true, name: 'Apenas Ativos' },
                    { id: false, name: 'Apenas Inativos' },
                ]}
                alwaysOn
            />
            <SelectInput
                label="Mostrar para Agente?"
                source="displayToShops"
                choices={[
                    { id: true, name: 'Apenas Ativos' },
                    { id: false, name: 'Apenas Inativos' },
                ]}
                alwaysOn
            />
            <SelectInput
                label="Status"
                source="active"
                choices={[
                    { id: true, name: 'Apenas Ativos' },
                    { id: false, name: 'Apenas Inativos' },
                    { id: null, name: 'Todos' },
                ]}
                alwaysOn
            />
            <SelectInput
                label="Destaque"
                source="featured"
                choices={[
                    { id: true, name: 'Sim' },
                    { id: false, name: 'Não' },
                ]}
                alwaysOn
            />
            <SelectInput
                label="Categoria"
                source="categoryProduct"
                choices={categories}
                alwaysOn
            />
        </Filter>
    );
}

export const ProductsList = ({ permissions, ...props }) => {
    const classes = useStyles()

    const CoveragesField = ({ record, source }) => {
        return (
            <span
                className={
                    (record.coveragesCount === '0' ||
                        parseFloat(record.coveragesFilleds) <
                        parseFloat(record.coveragesCount)) &&
                    classes.alertText
                }
            >
                {record[source]}
            </span>
        )
    }

    const FaresField = ({ record, source }) => {
        return (
            <span className={record[source] === '0' && classes.alertText}>
                {record[source]}
            </span>
        )
    }

    const DestinationsField = ({ record, source }) => {
        return (
            <span className={record[source] === '0' && classes.alertText}>
                {record[source]}
            </span>
        )
    }

    return (
        <List
            {...props}
            exporter={false}
            title="Produtos"
            bulkActionButtons={false}
            sort={{ field: 'name', order: 'ASC' }}
            filters={<ProductFilter />}
            filterDefaultValues={{ active: true }}
            perPage={25}
        >
            <Datagrid>
                <TextFieldRa source="name" label="Nome" />
                <TextFieldRa source="Insurer.name" label="Seguradora" />
                <TextFieldRa source="providerId" label="Código (Provedor)" />
                <TextFieldRa source="categoryProduct" label="Categoria" />
                <CoveragesField source="coverageCheck" label="Coberturas" />
                <FaresField source="faresCount" label="Tarifas" />
                <DestinationsField
                    source="destinationsCount"
                    label="Destinos"
                />
                <TextFieldRa source="markup" label="Markup %" />
                <BooleanField source="displayToCustomers" label="Mostrar para Cliente?" disabled />
                <BooleanField source="displayToShops" label="Mostrar para Agente?" disabled />
                <BooleanField source="active" label="Status" disabled />
                <BooleanField source="featured" label="Destaque" disabled />
                <ShowButton label="" />
                <EditButton label="" />
                <DeleteButton label="" mutationMode={'pessimistic'} />
            </Datagrid>
        </List>
    )
}

export const ProductsEdit = (props) => {
    const { record } = useEditController(props)
    const [insurerOptions, setInsurerOptions] = React.useState([])
    const categories = [
        { id: "EC", name: "Econômico" },
        { id: "IT", name: "Intermediário" },
        { id: "PR", name: "Premium" }]

    useEffect(() => {
        ; (async () => {
            try {
                const result = await api.get(`admin/insurers`)
                setInsurerOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar tags', error)
            }
        })()
    }, [])

    if (!record) return null

    return (
        <Edit {...props}>
            <TabbedForm>
                <FormTab label="Produto" redirect="list">
                    <SelectInput
                        label="Seguradora"
                        source="insurerId"
                        choices={insurerOptions}
                        resettable
                    />
                    <TextInput
                        label="Código (Provedor)"
                        source="providerId"
                        validate={[required()]}
                    />
                    <TextInput
                        label="Código Tarifa: AssistCard, Hero e Universal (se for acordo)"
                        source="fareCode"
                    />
                    <TextInput
                        label="Nome (Provedor)"
                        source="providerName"
                        validate={[required()]}
                    />
                    <TextInput
                        label="Nome para exibição"
                        source="name"
                        validate={[required()]}
                    />
                    <SelectInput
                        label="Categoria"
                        source="categoryProductId"
                        choices={categories}
                        validate={[required()]}
                        resettable
                    />
                    <TextInput label="Markup %" source="markup" />
                    <NumberInput
                        label="Nº mínimo de diárias"
                        source="minDaily"
                    />
                    <NumberInput
                        label="Nº máximo de diárias"
                        source="maxDaily"
                    />
                    <TextInput label="URL Video apresentado" source="videoUrl" />
                    <TextInput label="Titulo destaque" source="featuredTitle" />
                    <BooleanInput source="featured" label="Produto em destaque" />
                    <BooleanInput source="renew" label="Produto renovação" />
                    <BooleanInput
                        source="displayToCustomers"
                        label="Exibir para clientes (Site)"
                    />
                    <BooleanInput
                        source="displayToShops"
                        label="Exibir para lojas (Consultores)"
                    />
                    <BooleanInput
                        source="active"
                        label="Status"
                        validate={[required()]}
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

const ShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton />
        <EditButton basePath={basePath} record={data} />
    </TopToolbar>
)

export const ProductsShow = (props) => {
    const { record } = useShowController(props)
    const classes = useStyles()
    const checkAuth = useCheckAuth()
    const refresh = useRefresh()
    const handleCheckAuth = () => checkAuth()
    const redirect = useRedirect()

    const [insurerOptions, setInsurerOptions] = React.useState([])
    const [newTag, setNewTag] = React.useState('')
    const [openNewTagDialog, setOpenNewTagDialog] = React.useState(false)
    const [tagOptions, setTagOptions] = React.useState([])

    useEffect(() => {
        ; (async () => {
            try {
                const result = await api.get(`admin/insurers`)
                setInsurerOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar tags', error)
            }
        })()
    }, [])

    const handleNewTag = async (productTags) => {
        try {
            const result = await api.get(`admin/assistancetags`)
            productTags = productTags.map((a) => a.assistanceTagId)
            const tags = result.data.filter((a) => !productTags.includes(a.id))
            setTagOptions(tags)
            setNewTag('')
            setOpenNewTagDialog(true)
        } catch (error) {
            console.log('Erro ao consultar tags', error)
        }
    }

    const newProductTag = async (productId) => {
        try {
            await handleCheckAuth()
            await api.post(`admin/productTags`, {
                productId,
                assistanceTagId: newTag,
            })
            setOpenNewTagDialog(false)
            refresh()
        } catch (error) {
            console.log('erro ao remover tag do hotel')
        }
    }

    const handleChangeNewTag = (value) => {
        setNewTag(value)
    }

    const loadDefaultCoverages = async (productId) => {
        try {
            await handleCheckAuth()
            await api.post(`admin/products/loadDefaultCoverages`, {
                productId,
            })
            refresh()
        } catch (error) {
            console.log('erro ao carregar coberturas padrão')
        }
    }

    const duplicate = async (productId) => {
        try {
            await handleCheckAuth()
            const result = await api.post(`admin/products/duplicate`, {
                id: productId,
            })
            console.log(result)
            redirect(`/admin/products/${result.data.id}/show`)
        } catch (error) {
            console.log('erro ao carregar coberturas padrão')
        }
    }

    const coverageRowStyle = (record, index) => ({
        backgroundColor: record.incomplete ? '#ffe2e2' : 'white',
    })

    if (!record) return null

    return (
        <Show {...props} actions={<ShowActions />}>
            <TabbedShowLayout>
                <TabRa label="Produto" redirect="list">
                    <>
                        <TopToolbar className={classes.actions}>
                            <Button
                                variant="contained"
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    marginLeft: 0,
                                }}
                                onClick={() => duplicate(record.id)}
                                color="primary"
                            >
                                Duplicar
                            </Button>
                        </TopToolbar>
                        <SimpleForm mutationMode='pessimistic'>
                            <SelectField
                                label="Seguradora"
                                source="insurerId"
                                choices={insurerOptions}
                                resettable
                            />
                            <TextField
                                label="Código (Provedor)"
                                source="providerId"
                                validate={[required()]}
                            />
                            <TextField
                                label="Código Tarifa (AssistCard e Hero)"
                                source="fareCode"
                            />
                            <TextField
                                label="Nome (Provedor)"
                                source="providerName"
                                validate={[required()]}
                            />
                            <TextField
                                label="Nome para exibição"
                                source="name"
                                validate={[required()]}
                            />
                            <TextField
                                label="Categoria"
                                source="categoryProduct"
                                validate={[required()]}
                            />
                            <TextField label="Markup %" source="markup" />
                            <NumberField
                                label="Nº mínimo de diária"
                                source="minDaily"
                            />
                            <NumberField
                                label="Nº máximo de diárias"
                                source="maxDaily"
                            />
                            <TextField label="URL Video apresentado" source="videoUrl" />
                            <TextField label="Titulo destaque" source="featuredTitle" />
                            <BooleanField
                                source="featured"
                                label="Destaque"
                            />
                            <BooleanField
                                source="renew"
                                label="Produto renovação"
                            />
                            <BooleanField
                                source="displayToCustomers"
                                label="Exibir para clientes (Site)"
                            />
                            <BooleanField
                                source="displayToShops"
                                label="Exibir para lojas (Consultores)"
                            />
                        </SimpleForm>
                    </>
                </TabRa>

                {/* Tarifas */}
                <TabRa label="Tarifas">
                    <ReferenceManyField
                        reference="admin/productFareAges"
                        target="productId"
                        addLabel={false}
                        sort={{ field: 'startAge', order: 'ASC' }}
                    >
                        <>
                            <TopToolbar className={classes.actions}>
                                <Button
                                    component={Link}
                                    variant="contained"
                                    to={{
                                        pathname: `/admin/productFareAges/create`,
                                        state: { productId: record.id },
                                    }}
                                    color="primary"
                                >
                                    Adicionar Tarifa
                                </Button>
                            </TopToolbar>
                            <Datagrid>
                                <TextField
                                    label="Idade Inicial"
                                    source="startAge"
                                />
                                <TextField
                                    label="Idade Final"
                                    source="endAge"
                                />
                                <TextField label="Valor" source="value" />
                                <TextField label="Moeda" source="currency" />
                                <EditButton />
                                <DeleteButton
                                    redirect={false}
                                    mutationMode="pessimistic"
                                />
                            </Datagrid>
                        </>
                    </ReferenceManyField>
                </TabRa>

                {/* Coberturas */}
                <TabRa label="Coberturas">
                    <ReferenceManyField
                        reference="admin/productCoverages"
                        target="productId"
                        addLabel={false}
                        sort={{ field: 'Coverage.name', order: 'ASC' }}
                        perPage={25}
                        pagination={
                            <Pagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                            />
                        }
                    >
                        <>
                            <TopToolbar className={classes.actions}>
                                <Button
                                    variant="contained"
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        marginLeft: 0,
                                    }}
                                    onClick={() =>
                                        loadDefaultCoverages(record.id)
                                    }
                                    color="primary"
                                >
                                    Carregar Coberturas Padrão
                                </Button>
                                <Button
                                    component={Link}
                                    variant="contained"
                                    to={{
                                        pathname: `/admin/productCoverages/create`,
                                        state: { productId: record.id },
                                    }}
                                    color="primary"
                                >
                                    Adicionar Cobertura
                                </Button>
                            </TopToolbar>
                            <Datagrid rowStyle={coverageRowStyle}>
                                <TextField
                                    label="Cobertura"
                                    source="Coverage.name"
                                />
                                <TextField label="Valor" source="value" />
                                <BooleanField
                                    label="Sim/Não"
                                    source="valueBoolean"
                                />
                                <TextField label="Moeda" source="currency" />
                                <TextField label="Descrição" source="valueDescription" />
                                <EditButton />
                                <DeleteButton
                                    redirect={false}
                                    mutationMode="pessimistic"
                                />
                            </Datagrid>
                        </>
                    </ReferenceManyField>
                </TabRa>

                {/* Tags */}
                <TabRa label="Tags">
                    <ReferenceManyField
                        reference="admin/productTags"
                        target="productId"
                        addLabel={false}
                    >
                        <>
                            <Dialog //Adicionar tag
                                open={openNewTagDialog}
                                onClose={() => setOpenNewTagDialog(false)}
                                maxWidth="lg"
                            >
                                <DialogContent>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                    >
                                        <InputLabel id="demo-simple-select-outlined-label">
                                            Nova Tag
                                        </InputLabel>
                                        <Select
                                            labelId="newTag-label"
                                            id="newTag"
                                            value={newTag}
                                            onChange={(e) =>
                                                handleChangeNewTag(
                                                    e.target.value
                                                )
                                            }
                                            label="Nova Tag"
                                        >
                                            {tagOptions &&
                                                tagOptions.map((tag) => (
                                                    <MenuItem value={tag.id}>
                                                        {tag.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => newProductTag(record.id)}
                                        color="primary"
                                    >
                                        Confirmar
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            setOpenNewTagDialog(false)
                                        }
                                        color="primary"
                                        autoFocus
                                    >
                                        Cancelar
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <TopToolbar className={classes.actions}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() =>
                                        handleNewTag(record.ProductTags)
                                    }
                                    style={{ backgroundColor: 'Green' }}
                                >
                                    Adicionar Tag
                                </Button>
                            </TopToolbar>
                            <Datagrid>
                                <TextField source="name" />
                                <DeleteButton />
                            </Datagrid>
                        </>
                    </ReferenceManyField>
                </TabRa>

                {/* Destinos */}
                <TabRa label="Destinos">
                    <ReferenceManyField
                        reference="admin/productDestinations"
                        target="productId"
                        addLabel={false}
                        sort={{ field: 'Destination.name', order: 'ASC' }}
                    >
                        <>
                            <TopToolbar className={classes.actions}>
                                <Button
                                    component={Link}
                                    variant="contained"
                                    to={{
                                        pathname: `/admin/productDestinations/create`,
                                        state: { productId: record.id },
                                    }}
                                    color="primary"
                                >
                                    Adicionar Destino
                                </Button>
                            </TopToolbar>
                            <Datagrid>
                                <TextField
                                    label="Destino"
                                    source="Destination.name"
                                />
                                <DeleteButton
                                    redirect={false}
                                    mutationMode="pessimistic"
                                />
                            </Datagrid>
                        </>
                    </ReferenceManyField>
                </TabRa>
            </TabbedShowLayout>
        </Show>
    )
}

export const ProductsCreate = (props) => {
    const [insurerOptions, setInsurerOptions] = React.useState([])
    const categories = [{ id: "EC", name: "Econômico" }, { id: "IT", name: "Intermediário" }, { id: "PR", name: "Premium" }]

    useEffect(() => {
        ; (async () => {
            try {
                const result = await api.get(`admin/insurers`)
                setInsurerOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar tags', error)
            }
        })()
    }, [])

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <SelectInput
                    label="Seguradora"
                    source="insurerId"
                    choices={insurerOptions}
                    resettable
                />
                <TextInput
                    label="Código (Provedor)"
                    source="providerId"
                    validate={[required()]}
                />
                <TextInput
                    label="Código Tarifa (AssistCard e Hero)"
                    source="fareCode"
                />
                <TextInput
                    label="Nome (Provedor)"
                    source="providerName"
                    validate={[required()]}
                />
                <TextInput
                    label="Nome para exibição"
                    source="name"
                    validate={[required()]}
                />
                <SelectInput
                    label="Categoria"
                    source="categoryProduct"
                    choices={categories}
                    validate={[required()]}
                    resettable
                />
                <TextInput label="Markup %" source="markup" />
                <NumberInput label="Nº mínimo de diárias" source="minDaily" />
                <NumberInput label="Nº máximo de diárias" source="maxDaily" />
                <BooleanInput source="renew" label="Produto renovação" />
                <BooleanInput
                    source="displayToCustomers"
                    label="Exibir para clientes (Site)"
                />
                <BooleanInput
                    source="displayToShops"
                    label="Exibir para lojas (Consultores)"
                />
                <BooleanInput
                    source="active"
                    label="Status"
                    validate={[required()]}
                />
            </SimpleForm>
        </Create>
    )
}

