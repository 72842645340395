export const formatDate = (dateToFormat) => {
    let newDate = null
    if (dateToFormat instanceof Date)
        newDate = dateToFormat
    else
        newDate = new Date(dateToFormat)

    const date = [
        newDate.getUTCDate().toString().padStart(2, '0'),
        (newDate.getUTCMonth() + 1).toString().padStart(2, '0'),
        newDate.getUTCFullYear(),
    ]

    return date.join('/')
}

export const formatXmlDate = (dateString) => {
    let newDate = null
    if (dateString instanceof Date)
        newDate = dateString
    else
        newDate = new Date(dateString)

    const date = [
        newDate.getFullYear(),
        (newDate.getMonth() + 1).toString().padStart(2, '0'),
        newDate.getDate().toString().padStart(2, '0'),
    ]

    return date.join('-')
}

export const formatDateTime = (dateToFormat) => {
    let newDate = null
    if (dateToFormat instanceof Date)
        newDate = dateToFormat
    else
        newDate = new Date(dateToFormat)

    const date = [
        newDate.getDate().toString().padStart(2, '0'),
        (newDate.getMonth() + 1).toString().padStart(2, '0'),
        newDate.getFullYear(),
    ]

    const time = [
        newDate.getHours().toString().padStart(2, '0'),
        newDate.getMinutes().toString().padStart(2, '0'),
        newDate.getSeconds().toString().padStart(2, '0')
    ]

    return date.join('/') + " " + time.join(':')
}
